import { Checkbox, FormControlLabel, Grid, Typography } from '@mui/material';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { enGB } from 'date-fns/locale';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { SocialEventForm } from 'types';
import { isValidDate } from './PublishAtForm';

export default function StartAttendanceInfoForm(props: {
  eventFormState: any;
  setEventFormState: any;
  hasErrorState: any;
  setHasErrorState: any;
}) {
  const { eventFormState, setEventFormState, hasErrorState, setHasErrorState } =
    props;

  const [hasStart, setHasStart] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  useEffect(() => {
    if (eventFormState.startAttendance != null) {
      setHasStart(true);
    }
  }, [eventFormState.startAttendance]);

  const handleStartChange = () => {
    if (hasStart) {
      setHasErrorState((prev: any) => ({
        ...prev,
        startAttendance: false,
      }));
      setEventFormState((prev: SocialEventForm) => ({
        ...prev,
        startAttendance: null,
      }));
    } else {
      setHasErrorState((prev: any) => ({
        ...prev,
        startAttendance: true,
      }));
      setErrorMessage('Choose a date');
    }
    setHasStart(!hasStart);
  };

  const handleDateChange = (newValue: Date | null) => {
    if (newValue) {
      setHasErrorState((prev: any) => ({
        ...prev,
        startAttendance: !isValidDate(
          newValue,
          eventFormState,
          setErrorMessage
        ),
      }));
      props.setEventFormState((prev: SocialEventForm) => ({
        ...prev,
        startAttendance: moment(newValue),
      }));
    }
  };

  return (
    <>
      <Grid container item gap={2}>
        <Typography variant="h3">Attendance Window Start</Typography>
        <Grid container item>
          <FormControlLabel
            control={
              <Checkbox
                color="secondary"
                checked={hasStart}
                onChange={handleStartChange}
              />
            }
            label="Enable attendance at a specific time"
          />
          {hasStart && (
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              adapterLocale={enGB}
            >
              <DateTimePicker
                format="dd/MM/yyyy HH:mm"
                label={`Attendance Start`}
                ampm={false}
                value={eventFormState.startAttendance?.toDate() ?? null}
                minutesStep={15}
                maxDateTime={eventFormState.startTime.toDate()}
                minDateTime={eventFormState.publishDate?.toDate() ?? new Date()}
                onChange={handleDateChange}
                slotProps={{
                  textField: {
                    error: hasErrorState.startAttendance,
                    helperText: <>{errorMessage}</>,
                    fullWidth: true,
                  },
                }}
              />
            </LocalizationProvider>
          )}
        </Grid>
      </Grid>
    </>
  );
}
