import {
  Button,
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Tooltip,
} from '@mui/material';
import ConfirmationBox from 'components/ConfirmationBox/ConfirmationBox';
import LoadingIcon from 'components/LoadingIcon';
import UserSearch from 'components/UserSearch/UserSearch';
import useHttpDelete from 'hooks/useHttpDelete';
import useHttpGet from 'hooks/useHttpGet';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { UserBase, UserWithImage } from 'types';
import * as XLSX from 'xlsx';

export default function DeleteUserPage() {
  type Statuses = Record<string, string>;
  const [user, setUser] = useState<UserBase | null>(null);
  const [displayText, setDisplayText] = useState<boolean>(false);
  const [emails, setEmails] = useState<string[]>([]);
  const [, setSearchParams] = useSearchParams();
  const [statuses, setStatuses] = useState<Statuses>({});

  const { httpDelete, isLoading: isLoadingDelete, error } = useHttpDelete({});
  const [openConfirmationBox, setOpenConfirmationBox] = useState(false);

  const { data: users, reload } = useHttpGet<UserWithImage[]>('/users');

  function createStatuses(response: Statuses[]) {
    const newStatuses: Statuses = {};
    response.forEach((res) => {
      newStatuses[res.email] = res.status;
    });
    setStatuses(newStatuses);
  }

  async function deleteUsersFromEmail() {
    const response = await httpDelete('/users/DeleteFromEmails', emails);
    setSearchParams({ emails });
    createStatuses(response.data);
    setUser(null);
    reload();
  }

  async function deleteUser() {
    if (!user) return;
    await httpDelete(`/users/${user.appUserId}`).then(() => {
      setDisplayText(true);
      setSearchParams({ delete: user.appUserId });
      setUser(null);
      reload();
    });
  }

  const handleFileUpload = (event: any) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      if (e.target && e.target.result) {
        const data = new Uint8Array(e.target.result as ArrayBuffer);
        const workbook = XLSX.read(data, { type: 'array' });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const rawData = XLSX.utils
          .sheet_to_json<string[]>(worksheet, { header: 1 })
          .flat();
        const emails = rawData.filter(
          (cell) =>
            typeof cell === 'string' && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(cell)
        );
        setEmails(emails as string[]);
      }
    };
    reader.readAsArrayBuffer(file);
  };

  return (
    <>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        marginY={10}
        gap={6}
        columns={{ xs: 4, md: 12 }}
      >
        <Grid item>
          <Typography variant="h3">Delete User</Typography>
        </Grid>
        <Grid item style={{ width: '15em' }}>
          <UserSearch
            setUser={(user) => {
              setUser(user);
              setDisplayText(false);
            }}
            users={users}
          />
        </Grid>
        <Grid item>
          <Button
            disableElevation
            disabled={!user?.appUserId}
            color="primary"
            onClick={() => setOpenConfirmationBox(true)}
            variant="contained"
            sx={{
              backgroundColor: 'red',
              color: '#FFF',
            }}
          >
            Delete User
          </Button>
        </Grid>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          gap={2}
        >
          <Grid item>
            <input
              type="file"
              accept=".xlsx, .xls"
              onChange={handleFileUpload}
              style={{ display: 'none' }}
              id="file-upload"
            />
            <label htmlFor="file-upload">
              <Button component="span" variant="contained" color="primary">
                Upload File
              </Button>
            </label>
          </Grid>
          {emails.length > 0 && (
            <>
              <Grid
                item
                sx={{ width: '20rem', maxHeight: '20rem', overflowY: 'scroll' }}
              >
                <TableContainer component={Paper}>
                  <Table>
                    <TableBody>
                      {emails.map((email, index) => (
                        <TableRow key={index}>
                          <TableCell>{email}</TableCell>

                          <TableCell>
                            <Typography
                              color={
                                statuses[email] === 'Deleted' ? 'green' : 'red'
                              }
                            >
                              {statuses[email] || ''}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <Tooltip arrow title="Deletes all users in the table">
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: 'red',
                    color: '#FFF',
                  }}
                  onClick={() => deleteUsersFromEmail()}
                >
                  Delete Users
                </Button>
              </Tooltip>
            </>
          )}
        </Grid>
        {isLoadingDelete && (
          <Grid item>
            <LoadingIcon />
          </Grid>
        )}
        {!error && displayText && (
          <Grid item>
            <Typography color="red">{user?.fullName} deleted</Typography>
          </Grid>
        )}
      </Grid>
      <ConfirmationBox
        open={openConfirmationBox}
        title={`Are you sure that you want to delete ${user?.fullName}?`}
        content={'The user will be permanently deleted'}
        handleClose={() => {
          setOpenConfirmationBox(false);
        }}
        handleResponse={(response) => {
          if (response) deleteUser();
        }}
      />
    </>
  );
}
