import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import DeleteIcon from '@mui/icons-material/Delete';
import SendIcon from '@mui/icons-material/Send';
import {
  Button,
  Divider,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import UserAvatar from 'components/UserAvatar';
import useHttpGet from 'hooks/useHttpGet';
import useUser from 'hooks/useUser';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { ChatMessage } from 'types';

function ChatBox(props: { roomId: string }) {
  const { roomId } = props;
  const { userId, isAdmin } = useUser();

  const apiURL = process.env.REACT_APP_API_URI;

  const [onLoad, setOnLoad] = useState<boolean>(true);

  const [connection, setConnection] = useState<any>(null);

  const [message, setMessage] = useState<string>('');

  const [messageNumber, setMessageNumber] = useState<number>(0);

  const [messages, setMessages] = useState<ChatMessage[]>([]);

  const [isMoreMessages, setIsMoreMessages] = useState<boolean>(true);

  const { data: oldMessages } = useHttpGet<ChatMessage[]>(
    `/chat-messages/${roomId}/recent-messages?skips=${messageNumber}`
  );

  useEffect(() => {
    JoinRoom();
  }, []);

  useEffect(() => {
    UpdateMessages();
    ScrollChatDownOnLoad();
  }, [oldMessages]);

  async function UpdateMessages() {
    setMessages((prev) => [...(oldMessages ?? []), ...prev]);
    setIsMoreMessages((oldMessages?.length ?? 0) === 10);
  }

  async function JoinRoom() {
    try {
      const connection = new HubConnectionBuilder()
        .withUrl(`${apiURL}/chathub`) //Get variable from a .env file
        .configureLogging(LogLevel.Information)
        .build();

      connection.on('JoinedRoom', (message: string) => {
        console.log(message);
      });

      connection.on('ReceiveMessage', (chatMessage: ChatMessage) => {
        SaveMessage(chatMessage);
        // ScrollChatDown();
      });

      connection.on('DeletedMessage', (messageId) => {
        RemoveMessage(messageId);
      });

      await connection.start();
      await connection.invoke('JoinRoom', roomId, userId);
      setConnection(connection);
    } catch (e) {
      console.log(e);
    }
  }

  async function onEnterSubmit(e: any) {
    if (e.keyCode === 13) SendMessage();
  }

  async function SendMessage() {
    await connection.invoke('SendMessage', roomId, userId, message);
    setMessage('');
  }

  async function DeleteMessage(messageId: string) {
    await connection.invoke('DeleteMessage', roomId, messageId);
  }

  async function SaveMessage(chatMessage: ChatMessage) {
    setMessages((prev) => [...prev, chatMessage]);
  }
  async function RemoveMessage(messageId: string) {
    setMessages((prev) => [...prev.filter((m) => m.messageId !== messageId)]); //Not tested
  }

  function ScrollChatDownOnLoad() {
    if (!onLoad || isMobile) return;
    setTimeout(() => {
      const chatInput = document.getElementById('chat-input') as HTMLElement;
      chatInput.scrollIntoView();
      window.scrollTo({ top: 0 });
    }, 50);
    setOnLoad(true);
  }

  function ScrollChatDown() {
    setTimeout(() => {
      const chatInput = document.getElementById('chat-input') as HTMLElement;
      chatInput.scrollIntoView();
      const chatHead = document.getElementById('chat-head') as HTMLElement;
      const y =
        chatHead.getBoundingClientRect().bottom + window.pageYOffset - 80;
      window.scrollTo({ top: y, behavior: 'auto' });
    }, 50);
  }

  function IsUserMessage(appUserId: string) {
    return appUserId === userId;
  }

  return (
    <>
      <Typography variant="h3" id="chat-head" marginTop={'1em'}>
        Chat
      </Typography>
      <Grid
        container
        justifyContent="space-between"
        sx={{
          borderRadius: 1,
          height: '25em',
          overflow: 'auto',
          border: 1,
          p: '1em',
          width: '100%',
        }}
      >
        <Grid
          container
          direction="column"
          justifyContent="left"
          alignItems="center"
          gap={2}
        >
          {isMoreMessages && (
            <Grid
              container
              direction="column"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Button
                disableElevation
                onClick={() => setMessageNumber((prev) => prev + 10)}
              >
                <Typography color="secondary">Load older messages</Typography>
              </Button>
            </Grid>
          )}
          {messages?.map((m) => (
            <>
              <Grid container alignItems="center" justifyContent="center">
                <Divider variant="middle" sx={{ width: '100%' }} />
                <Typography variant="body2">
                  {moment(m.timePosted).local().format('MMM Do HH:mm')}
                </Typography>
              </Grid>
              <Grid
                key={m.messageId}
                container
                direction={
                  IsUserMessage(m.appUser.appUserId) ? 'row-reverse' : 'row'
                }
                justifyContent={
                  IsUserMessage(m.appUser.appUserId) ? 'right' : 'left'
                }
                alignItems="center"
                gap={2}
              >
                <Grid
                  container
                  direction={
                    IsUserMessage(m.appUser.appUserId) ? 'row-reverse' : 'row'
                  }
                  justifyContent={
                    IsUserMessage(m.appUser.appUserId) ? 'right' : 'left'
                  }
                  alignItems="center"
                  gap={2}
                >
                  <UserAvatar
                    imageUrl={m.appUser?.imageUrl ?? ''}
                    fullName={m.appUser?.fullName ?? ''}
                    size={32}
                  />
                  <Typography variant={'body2'}>
                    {m.appUser?.fullName ?? ''}
                  </Typography>
                </Grid>
                <Grid
                  container
                  direction={
                    IsUserMessage(m.appUser.appUserId) ? 'row' : 'row-reverse'
                  }
                  justifyContent={
                    IsUserMessage(m.appUser.appUserId) ? 'right' : 'left'
                  }
                  alignItems="center"
                >
                  <Typography>{m.message}</Typography>
                  {(IsUserMessage(m.appUser.appUserId) || isAdmin) && (
                    <IconButton onClick={() => DeleteMessage(m.messageId)}>
                      <DeleteIcon />
                    </IconButton>
                  )}
                </Grid>
              </Grid>
            </>
          ))}
        </Grid>
        {!isMobile && (
          <Grid
            container
            direction="column"
            justifyContent="flex-end"
            alignItems="center"
            gap={2}
          >
            <Grid
              container
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              gap={2}
            >
              <Grid item xs={10}>
                <TextField
                  onChange={(e) => setMessage(e.target.value)}
                  value={message}
                  onKeyDown={(e) => onEnterSubmit(e)}
                  sx={{ width: '100%' }}
                  id="chat-input"
                />
              </Grid>
              <Grid item xs alignItems={'right'}>
                <IconButton onClick={SendMessage}>
                  <SendIcon>Send message</SendIcon>
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        )}
        {isMobile && (
          <Grid
            container
            direction="column"
            justifyContent="flex-end"
            alignItems="center"
            gap={2}
          >
            <Grid
              container
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              gap={2}
            >
              <Grid item xs={9}>
                <TextField
                  onChange={(e) => setMessage(e.target.value)}
                  value={message}
                  onKeyDown={(e) => onEnterSubmit(e)}
                  sx={{ width: '100%' }}
                />
              </Grid>
              <Grid item xs={2} alignItems={'right'}>
                <IconButton onClick={SendMessage}>
                  <SendIcon>Send message</SendIcon>
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  );
}

export default ChatBox;
