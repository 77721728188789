import Avatar from '@mui/material/Avatar';
import GetImageUrl from 'helpers/GetImage';

function stringToColor(string: string) {
  let hash = 0;
  for (let i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';
  for (let i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }

  return color;
}

function getInitials(name: string) {
  const splittedName = name.split(' ', 2);
  return splittedName.length <= 1
    ? name[0]
    : `${splittedName[0][0] + splittedName[1][0]}`;
}

function stringAvatar(name: string, size: number) {
  return {
    sx: {
      bgcolor: stringToColor(name),
      fontSize: size / 2,
    },
    children: getInitials(name),
  };
}

function UserAvatar(props: {
  fullName: string;
  imageUrl?: string;
  file?: any;
  size?: number;
}) {
  const { fullName, imageUrl, file } = props;
  const size = props.size || 56;

  if (imageUrl || file) {
    return (
      <Avatar
        src={file ?? GetImageUrl(imageUrl!)}
        style={{ width: size, height: size }}
      />
    );
  }

  return (
    <Avatar
      {...stringAvatar(fullName, size)}
      style={{ width: size, height: size }}
    />
  );
}

export default UserAvatar;
