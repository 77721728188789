import { Button, Grid } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';

function NavButtons(props: { isGroup: boolean }) {
  const { isGroup } = props;
  const city = useSearchParams()[0].get('city');
  const navigate = useNavigate();
  const TopButton = (
    isContained: boolean,
    text: string,
    customUrl: string | undefined = undefined
  ) => (
    <Button
      disableElevation
      onClick={() =>
        navigate(`/${customUrl ?? text.toLowerCase()}?city=${city}`)
      }
      variant={isContained ? 'contained' : 'outlined'}
      style={{
        color: !isContained ? 'secondary.main' : undefined,
      }}
    >
      {text}
    </Button>
  );

  return (
    <Grid container gap={2}>
      {TopButton(isGroup, 'Groups')}
      {TopButton(!isGroup, 'Activities', 'events')}
    </Grid>
  );
}

export default NavButtons;
