import { Box } from '@mui/material';
import useHttpGet from 'hooks/useHttpGet';
import { useEffect, useState } from 'react';
import Typewriter from 'typewriter-effect';
import { allCityColor } from '../../atoms/themeState';
import { locationDesign } from './types';

function shuffle(array: locationDesign[]) {
  return [...array].sort(() => 0.5 - Math.random());
}

function RotatingCities() {
  const { data } = useHttpGet<locationDesign[]>('/locations/design');

  const [locations, setLocations] = useState<locationDesign[]>([
    { city: 'one place', color: allCityColor },
  ]);

  useEffect(() => {
    data && setLocations(locations.concat(shuffle(data)));
  }, [data]);

  return (
    <>
      {'in '}
      <Box sx={{ display: 'inline-block' }}>
        {locations && (
          <Typewriter
            options={{
              autoStart: true,
              loop: true,
              strings: locations.map(
                (location) =>
                  `<span style="background-color: ${location.color}4C">${location.city}</span>`
              ),
            }}
          />
        )}
      </Box>
    </>
  );
}

export default RotatingCities;
