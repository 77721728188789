import {
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import Calendar from 'components/Calendar';
import LoadingIcon from 'components/LoadingIcon';
import useHttpGet from 'hooks/useHttpGet';
import useUser from 'hooks/useUser';
import moment from 'moment';
import { useState } from 'react';
import { SocialEventCalendar } from 'types';
import '../../styles/schedule.scss';
import EventContainer from './EventContainer';
import ScheduleIntroduction from './ScheduleIntroduction';
import ScheduleView from './ScheduleView';

const sortWeekly = (events: SocialEventCalendar[]) => {
  const years: any = {};
  let weeks: any = {};

  let curYear: number;
  events.forEach((event: SocialEventCalendar, index: number) => {
    // Start at current year, reset weekly events each year
    if (index === 0) {
      curYear = moment(event.startTime).local().year();
    } else {
      if (moment(event.startTime).local().year() !== curYear) {
        curYear = moment(event.startTime).year();
        weeks = {};
      }
    }

    // Fill the current year with weekly events
    const curWeek = moment(event.startTime).isoWeek();
    if (curWeek.toString() in weeks) {
      weeks[curWeek.toString()].push(event);
    } else {
      weeks[curWeek.toString()] = [event];
    }
    years[curYear.toString()] = weeks;
  });

  return years;
};

const getAttendingEventIds = (events: SocialEventCalendar[]) => {
  return events.map((event: SocialEventCalendar) => event.eventId);
};

function ScheduleCalender() {
  // User and location info
  const { userId, location, memberships } = useUser();
  const { data: upcomingLocationEvents, isLoading: isLoadingUpcomingEvents } =
    useHttpGet<SocialEventCalendar[]>(
      `/locations/${location}/upcoming-events/published`
    );
  const { data: attendingEvents } = useHttpGet<SocialEventCalendar[]>(
    `users/${userId}/events`
  );

  // Monthly/weekly view
  const [isMonthly, setIsMonthly] = useState(false);

  // Data type filter
  const sortOptions = ['All activities', 'My groups', 'Attending activities'];
  const [selectedSort, setSelectedSort] = useState('My groups');

  const handleSortChange = (e: any) => {
    setSelectedSort(e.target.value);
  };
  const filterData = (sortBy: string, events: SocialEventCalendar[]) => {
    // Show all activities for location
    if (sortBy === sortOptions[0]) return events;

    // Show only activities for the groups I am attending
    if (sortBy === sortOptions[1]) {
      const myGroupsEvents = events.filter((event: SocialEventCalendar) => {
        return memberships.includes(event.hostingGroups[0].socialGroupId);
      });
      return myGroupsEvents;
    }

    // Show only activities that I am attending
    if (sortBy === sortOptions[2]) {
      const myEvents = events.filter((event: SocialEventCalendar) => {
        return getAttendingEventIds(attendingEvents ?? []).includes(
          event.eventId
        );
      });
      return myEvents;
    }
  };

  return (
    <Grid container direction={'column'} gap={2}>
      <ScheduleIntroduction />
      <ScheduleView isMonthly={isMonthly} setIsMonthly={setIsMonthly} />
      <Grid container item gap={2} my={1}>
        <Grid item xs={12} md={3}>
          <FormControl fullWidth>
            <InputLabel id="filter-by">Filter by</InputLabel>
            <Select
              labelId="filter-by"
              label="filter by"
              id="filter-by"
              value={selectedSort}
              onChange={handleSortChange}
            >
              {sortOptions?.map((sortOption, index) => (
                <MenuItem key={index} value={sortOption}>
                  {sortOption}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Divider />
      {isLoadingUpcomingEvents ? (
        <LoadingIcon />
      ) : (
        <>
          {isMonthly ? (
            <Calendar
              preloadedEvents={
                filterData(selectedSort, upcomingLocationEvents ?? []) ?? []
              }
            />
          ) : (
            <EventContainer
              events={
                sortWeekly(
                  filterData(selectedSort, upcomingLocationEvents ?? []) ?? []
                ) ?? {}
              }
              attendingEventIds={getAttendingEventIds(attendingEvents ?? [])}
            />
          )}
        </>
      )}
    </Grid>
  );
}

export default ScheduleCalender;
