import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import './index.scss';

const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export default function Navigation(props: { date: Date; setDate: any }) {
  const { date, setDate } = props;

  return (
    <>
      <Box className="navigation">
        <Box className="navigation-option"></Box>
        <Box className="navigation-option">
          <Box className="navigation-box">
            <NavigateBeforeIcon
              color="primary"
              sx={{ cursor: 'pointer', fontSize: '35px' }}
              onClick={() => {
                const newDate = new Date(date);
                newDate.setMonth(newDate.getMonth() - 1);
                setDate(newDate);
              }}
            />
            <Typography variant="h3">
              {MONTHS[date.getMonth()]} {date.getFullYear()}
            </Typography>
            <NavigateNextIcon
              color="primary"
              sx={{ cursor: 'pointer', fontSize: '35px' }}
              onClick={() => {
                const newDate = new Date(date);
                newDate.setMonth(newDate.getMonth() + 1);
                setDate(newDate);
              }}
            />
          </Box>
        </Box>
        <Box className="navigation-option"></Box>
      </Box>
    </>
  );
}
