import { Autocomplete, Box, Grid, TextField, Typography } from '@mui/material';
import MapWithSearch from 'components/CreateEventForm/MapWithSearch';
import { useState } from 'react';
import { SocialEventForm } from 'types';

function AddressInfoForm(props: {
  eventFormState: SocialEventForm;
  setEventFormState: any;
  hasErrorState: any;
  setHasErrorState: any;
}) {
  const { eventFormState, setEventFormState, hasErrorState, setHasErrorState } =
    props;

  const [query, setQuery] = useState<string>('');
  const [addressOptions, setAddressOptions] = useState<string[]>([]);
  return (
    <Grid container item gap={2}>
      <Typography variant="h3">
        Address Information
        <Box component="span" color="red" ml={0.5}>
          *
        </Box>
      </Typography>
      <Grid container item gap={2}>
        <Grid container item direction="column" md={5} gap={2}>
          <Autocomplete
            placeholder="Maskinveien 24"
            disablePortal
            fullWidth
            autoSelect
            value={eventFormState.address}
            inputValue={query}
            onChange={(_, value) => {
              setHasErrorState((prev: any) => ({
                ...prev,
                address: !value,
              }));
              setEventFormState((prev: any) => ({
                ...prev,
                address: value,
              }));
            }}
            onInputChange={(_, value: string) => setQuery(value)}
            options={addressOptions}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Address"
                placeholder="Maskinveien 24"
                error={hasErrorState.address}
              />
            )}
          />
        </Grid>
        <Grid container item md>
          <MapWithSearch query={query} setResults={setAddressOptions} />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default AddressInfoForm;
