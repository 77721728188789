import imageCompression from 'browser-image-compression';

const CompressImage = async (imageFile: File): Promise<any> => {
  const options = {
    maxSizeMB: 0.5,
    maxWidthOrHeight: 850,
    useWebWorker: true,
  };
  try {
    const compressedFile = await imageCompression(imageFile, options);
    return compressedFile;
  } catch (error) {
    console.log(error);
  }
};

const CompressProfilePicture = async (imageFile: File): Promise<any> => {
  const options = {
    maxSizeMB: 0.3,
    maxWidthOrHeight: 250,
    useWebWorker: true,
  };
  try {
    const compressedFile = await imageCompression(imageFile, options);
    return compressedFile;
  } catch (error) {
    console.log(error);
  }
};

const GiveFileImageType = (file: File) => {
  const blob = file.slice(0, file.size, 'image/jpeg');
  const newFile = new File([blob], 'image', {
    type: blob.type,
  });
  return newFile;
};

export { CompressImage, CompressProfilePicture, GiveFileImageType };
