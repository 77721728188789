import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material';
import { SetStateFunc } from 'helpers/utilities';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { SocialEventForm } from 'types';

const displayDate = (date: any) => {
  return date.format('MMMM Do, HH:mm');
};

const getAllEventDates = (formState: SocialEventForm) => {
  let allDates = [];

  // Get selected date from event information
  allDates.push([
    displayDate(formState.startTime),
    displayDate(formState.endTime),
    displayDate(formState.publishDate ? formState.publishDate : moment()),
    displayDate(
      formState.deadlineAttendance ? formState.deadlineAttendance : moment()
    ),
    displayDate(
      formState.startAttendance ? formState.startAttendance : moment()
    ),
  ]);

  // Add future dates based on frequency
  for (let i = 0; i < formState.maxReccurance; i++) {
    let newStartTime = moment(formState.startTime);
    let newEndTime = moment(formState.endTime);
    let newPublishDate = moment(formState.publishDate);
    let newDeadlineAttendance = moment(formState.deadlineAttendance);
    let newStartAttendance = moment(formState.startAttendance);

    newStartTime.add(formState.frequency * (i + 1), 'days');
    newEndTime.add(formState.frequency * (i + 1), 'days');
    newPublishDate.add(formState.frequency * (i + 1), 'days');
    newDeadlineAttendance.add(formState.frequency * (i + 1), 'days');
    newStartAttendance.add(formState.frequency * (i + 1), 'days');

    allDates.push([
      displayDate(newStartTime),
      displayDate(newEndTime),
      displayDate(newPublishDate),
      displayDate(newDeadlineAttendance),
      displayDate(newStartAttendance),
    ]);
  }
  return allDates;
};

function FrequencyInfoForm(props: {
  isCreate: boolean;
  eventFormState: SocialEventForm;
  setEventFormState: SetStateFunc<SocialEventForm>;
  hasErrorState: any;
  setHasErrorState: any;
}) {
  const { eventFormState, setEventFormState, isCreate } = props;
  const [maxTimes, setMaxTimes] = useState(0);
  const eventDates = getAllEventDates(eventFormState);

  useEffect(() => {
    if (eventFormState.frequency <= 0) {
      setEventFormState((prev) => ({
        ...prev,
        maxReccurance: 0,
      }));
      return;
    }
    const maxDeltaDays = 6 * 30;
    setMaxTimes(maxDeltaDays / eventFormState.frequency);
  }, [eventFormState.frequency]);

  return (
    <Grid container item gap={2}>
      <Typography variant="h3">Frequency</Typography>
      <Grid container alignItems="center" gap={2}>
        <Grid item xs={12}>
          <Typography>
            The frequency allows you to publish several activities at the same
            time of day, but at future dates
          </Typography>
        </Grid>
        <Grid item xs={12} sm={3}>
          <FormControl fullWidth>
            <InputLabel id="frequency-dropdown">Frequency</InputLabel>
            <Select
              disabled={!isCreate}
              defaultValue={0}
              autoWidth
              labelId="frequency-dropdown"
              label="Frequency"
              value={eventFormState.frequency}
              onChange={(e: SelectChangeEvent<number>) =>
                setEventFormState((prev: SocialEventForm) => ({
                  ...prev,
                  frequency: e.target.value as number,
                }))
              }
            >
              <MenuItem value={0}>Once</MenuItem>
              <MenuItem value={7}>Weekly</MenuItem>
              <MenuItem value={14}>Every two weeks</MenuItem>
              <MenuItem value={30}>Monthly</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        {eventFormState.frequency > 0 && (
          <Grid item xs={12} sm={3}>
            <FormControl fullWidth>
              <TextField
                label="Recurrances"
                type="number"
                value={eventFormState.maxReccurance}
                inputProps={{ min: 1, max: maxTimes }}
                onChange={(e) => {
                  setEventFormState((prev: any) => ({
                    ...prev,
                    maxReccurance: parseInt(e.target.value),
                  }));
                }}
              />
            </FormControl>
          </Grid>
        )}
        <Grid item xs={12}>
          These activities will be created: <br />
          <ol>
            {eventDates.map((dateArray: any, index: number) => {
              return (
                <li key={index}>
                  {dateArray[0]} - {dateArray[1]}
                  {index === 0
                    ? ' (based on activity information)'
                    : ' (recurrent activity)'}
                  {eventFormState.publishDate && (
                    <ul>
                      <li>Automatically published at: {dateArray[2]}</li>
                    </ul>
                  )}
                  {eventFormState.startAttendance && (
                    <ul>
                      <li>Attendance starts at: {dateArray[4]}</li>
                    </ul>
                  )}
                  {eventFormState.deadlineAttendance && (
                    <ul>
                      <li>Deadline for attendance at: {dateArray[3]}</li>
                    </ul>
                  )}
                </li>
              );
            })}
          </ol>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default FrequencyInfoForm;
