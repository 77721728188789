import { Box, Button } from '@mui/material';
import CardContent from '@mui/material/CardContent';
import CardImage from 'components/EventOrGroupCard/CardImage';
import GetImageUrl from 'helpers/GetImage';
import { SocialGroupOverview } from 'types';
import '../../styles/groupcard.scss';
import { GroupDescription } from './GroupInfo';

function GroupCard(props: SocialGroupOverview) {
  const { name, imageUrl, description, socialGroupId } = props;

  return (
    <>
      <CardImage
        imageUrl={socialGroupId === -1 ? imageUrl : GetImageUrl(imageUrl)}
        title={name}
        isEvent={false}
      />
      <CardContent>
        <GroupDescription description={description} />
      </CardContent>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          position: 'absolute',
          bottom: '10px',
          left: '0',
          right: '0',
          width: '100%',
        }}
      >
        <Button
          disabled={true}
          color="primary"
          variant="outlined"
          sx={{
            width: '96%',
            marginright: '0px',
            float: 'left',
          }}
        >
          Learn more
        </Button>
      </Box>
    </>
  );
}

export default GroupCard;
