import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import useHttpGet from 'hooks/useHttpGet';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { LocationBase } from 'types';

function CitySelect() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { data } = useHttpGet<LocationBase[]>('/locations');
  const [cityOptions, setCityOptions] = useState<string[]>(['all']);
  const [city, setCity] = useState('all');

  useEffect(() => {
    if (data) {
      setCityOptions([
        'all',
        ...data.map((location) => location.city.toLowerCase()),
      ]);
    }
  }, [data]);

  useEffect(() => {
    if (data) {
      setCity(searchParams.get('city') || 'all');
    }
  }, [data, searchParams]);

  const handleNavigate = (event: SelectChangeEvent<string>) => {
    setSearchParams({ city: event.target.value });
  };

  return (
    <>
      <FormControl fullWidth>
        <InputLabel id="city-select">City</InputLabel>
        <Select
          labelId="city-select"
          label="city"
          id="city-select"
          value={city}
          onChange={handleNavigate}
          style={{ textTransform: 'capitalize' }}
        >
          {cityOptions?.map((city, index) => (
            <MenuItem
              key={index}
              value={city}
              style={{ textTransform: 'capitalize' }}
            >
              {city}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
}

export default CitySelect;
