import { Typography } from '@mui/material';
import LoadingIcon from 'components/LoadingIcon';
import useUser from 'hooks/useUser';

function PageNotFound() {
  const { isLoading } = useUser();

  return (
    <>
      {isLoading && <LoadingIcon />}
      {!isLoading && <Typography>Page not found..</Typography>}
    </>
  );
}

export default PageNotFound;
