import { Grid } from '@mui/material';
import ChooseFile from 'components/FileHandler/ChooseFile';
import UserAvatar from 'components/UserAvatar';
import useUser from 'hooks/useUser';

function ProfilePicture(props: {
  fileSelectedHandler: any;
  file: any;
  isEditing: boolean;
}) {
  const { fullName: name, imageUrl } = useUser();
  const { file, fileSelectedHandler, isEditing } = props;

  return (
    <>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        gap={1}
      >
        <UserAvatar
          size={100}
          fullName={name}
          imageUrl={imageUrl}
          file={file}
        />
        {isEditing && <ChooseFile fileSelectedHandler={fileSelectedHandler} />}
      </Grid>
    </>
  );
}
export default ProfilePicture;
