import axiosInstance from 'api/axiosInstance';
import { AxiosResponse } from 'axios';
const getAttendeeList = async (eventId: number, accessToken: string) => {
  return await axiosInstance
    .get(`/events/${eventId}/attendees/download`, {
      responseType: 'blob',
      headers: {
        Accept: 'text/csv;application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    })
    .then(createDownloadFile(eventId))
    .catch((err) => {
      alert('Could not generate csv. Please try again later');
      console.log(err);
    });
};

const createDownloadFile = (eventId: number) => (res: AxiosResponse) => {
  const href = URL.createObjectURL(res.data);

  const link = document.createElement('a');
  link.href = href;
  link.setAttribute('download', `event${eventId}-attendees.csv`);
  document.body.appendChild(link);
  link.click();

  document.body.removeChild(link);
  URL.revokeObjectURL(href);
};

export default getAttendeeList;
