import ArrowForwardIcon from '@mui/icons-material/ArrowForwardIos';
import { Button, Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router';

export default function ManageUsersPage() {
  const navigate = useNavigate();

  const ViewEventsButton = (linkTo: string) => (
    <Button
      disableElevation
      endIcon={<ArrowForwardIcon />}
      sx={{ color: 'primary.main', width: '300px' }}
      onClick={() => navigate(`/management/users/${linkTo}`)}
    >
      <Typography variant="h3">{linkTo} users</Typography>
    </Button>
  );

  return (
    <>
      <Grid
        container
        alignItems="center"
        direction="column"
        marginTop="3em"
        spacing="2em"
      >
        <Grid item>{ViewEventsButton('create')}</Grid>
        <Grid item>{ViewEventsButton('delete')}</Grid>
      </Grid>
    </>
  );
}
