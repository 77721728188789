import { Box, Button } from '@mui/material';
import CardContent from '@mui/material/CardContent';
import CardImage from 'components/EventOrGroupCard/CardImage';
import GetImageUrl from 'helpers/GetImage';
import { useNavigate } from 'react-router';
import { SocialGroupOverview } from 'types';
import '../../styles/groupcard.scss';
import { GroupDescription } from './GroupInfo';

function GroupCard(props: SocialGroupOverview) {
  const { name, imageUrl, description, socialGroupId } = props;
  const navigate = useNavigate();

  return (
    <>
      <div onClick={() => navigate(`/groups/${socialGroupId}`)}>
        <CardImage
          imageUrl={socialGroupId === -1 ? imageUrl : GetImageUrl(imageUrl)}
          title={name}
          isEvent={false}
        />
        <CardContent>
          <GroupDescription description={description} />
        </CardContent>
      </div>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          position: 'absolute',
          bottom: '10px',
          left: '0',
          right: '0',
        }}
      >
        <Button
          disableElevation
          disabled={socialGroupId === -1 ? true : false}
          color="primary"
          variant="outlined"
          onClick={() => navigate(`/groups/${socialGroupId}`)}
          sx={{
            width: '96%',
            whiteSpace: 'nowrap',
            float: 'left',
          }}
        >
          Learn more
        </Button>
      </Box>
    </>
  );
}

export default GroupCard;
