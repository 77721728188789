import { atom } from 'recoil';
// import type { User } from 'types';

export const currentLocation = atom({
  key: 'currentLocation',
  default: 'base',
});

// export const sideNavChanges = atom({
//   key: 'sideNavChanges',
//   default: {
//     events: false,
//     groups: false,
//   },
// });

export const currentUserState = atom({
  key: 'currentUserState',
  default: {
    userId: '',
    fullName: '',
    email: '',
    location: '',
    imageUrl: '',
    isAuthenticated: false,
    isCapgemini: false,
    isLoading: true,
    memberships: [] as number[],

    // Reconsider, since we cannot guarantee that OTHERS refresh their browsers
    isCoreMember: false,
    isAdmin: false,
    isCoreMemberOrAdmin: false,
    authorizedGroups: [] as number[],
    authorizedEvents: [] as number[],
  },
});

// export const userCityState = atom<string>({
//   key: 'userCityState',
//   default: 'Stavanger',
// });

// export const activeUser = atom({
//   key: 'activeUser',
//   default: undefined as User | undefined,
// });
