import ArrowForwardIcon from '@mui/icons-material/ArrowForwardIos';
import CloseIcon from '@mui/icons-material/Close';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContentText,
  DialogTitle,
  Grid,
} from '@mui/material';
import { isMobile } from 'react-device-detect';
import { useNavigate } from 'react-router';
import '../../styles/eventpopup.scss';

function LocationDialog(props: { open: boolean; setOpen: any }) {
  const { open, setOpen } = props;
  const navigate = useNavigate();

  return (
    <>
      <Dialog open={open}>
        <CloseIcon className="cross-corner" onClick={() => setOpen(false)} />
        <Grid
          container
          justifyContent={'center'}
          width={isMobile ? '300px' : '500px'}
        >
          <DialogTitle>Update profile</DialogTitle>
          <DialogContentText padding={3}>
            There is no location selected for your profile. You can update your
            location on the profile page.
          </DialogContentText>
          <DialogActions>
            <Button
              variant="outlined"
              color="primary"
              endIcon={<ArrowForwardIcon />}
              onClick={() => navigate('/user')}
            >
              Visit profile
            </Button>
          </DialogActions>
        </Grid>
      </Dialog>
    </>
  );
}

export default LocationDialog;
