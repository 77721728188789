import CreateGroupForm from 'components/CreateGroupForm';
import EditGroupForm from 'components/EditGroupForm';
import { useParams } from 'react-router';

export default function CreateOrEditGroupPage() {
  const { groupId } = useParams();

  return (
    <>{groupId ? <EditGroupForm groupId={groupId} /> : <CreateGroupForm />}</>
  );
}
