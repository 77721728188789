import { Button, Grid } from '@mui/material';

function ScheduleView(props: { isMonthly: boolean; setIsMonthly: any }) {
  const { isMonthly, setIsMonthly } = props;
  return (
    <>
      <Grid container gap={2} my={1}>
        <Button
          disableElevation
          onClick={() => setIsMonthly(false)}
          variant={!isMonthly ? 'contained' : 'outlined'}
          style={{
            color: isMonthly ? 'secondary.main' : undefined,
          }}
        >
          Weekly
        </Button>
        <Button
          disableElevation
          onClick={() => setIsMonthly(true)}
          variant={isMonthly ? 'contained' : 'outlined'}
          style={{
            color: !isMonthly ? 'secondary.main' : undefined,
          }}
        >
          Monthly
        </Button>
      </Grid>
    </>
  );
}
export default ScheduleView;
