import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useState } from 'react';
import { isMobile } from 'react-device-detect';
import { SocialEventCalendar } from 'types';
import MiniEvent from './MiniEvent';
import './index.scss';

const DAYS_SHORT = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

const toStartOfDay = (date: Date | string) => {
  const newDate = new Date(date);
  newDate.setHours(0);
  newDate.setMinutes(0);
  newDate.setSeconds(0);
  newDate.setMilliseconds(0);
  return newDate;
};

const findEventsForDate = (events: SocialEventCalendar[], date: Date) => {
  const dateTime = date.getTime();

  return events.filter((event: SocialEventCalendar) => {
    const eventFromTime = toStartOfDay(new Date(event.startTime)).getTime();
    const eventToTime = toStartOfDay(new Date(event.endTime)).getTime();

    return dateTime >= eventFromTime && dateTime <= eventToTime;
  });
};

const isCurrentDay = (date: Date) => {
  const currentDate = toStartOfDay(new Date());
  return date.getTime() === currentDate.getTime() ? 'current' : '';
};

const isCurrentMonth = (date: Date, selectedDate: Date) => {
  return date.getMonth() !== selectedDate.getMonth() ? 'otherMonth' : '';
};

const getDatesInMonth = (startDate: Date, events: SocialEventCalendar[]) => {
  const ROWS_COUNT = 5;
  const dates = [];
  for (let i = 0; i < ROWS_COUNT * 7; i++) {
    const date = new Date(startDate);
    dates.push({ date, events: findEventsForDate(events, date) });
    startDate.setDate(startDate.getDate() + 1);
  }
  return dates;
};

export default function CalendarGrid(props: {
  selectedDate: Date;
  events: SocialEventCalendar[];
  setDayViewDate: any;
}) {
  const { selectedDate, events, setDayViewDate } = props;

  // Finds the closest Monday relative to the first day of month/year combination
  const startingDate = new Date(
    selectedDate.getFullYear(),
    selectedDate.getMonth(),
    1
  );
  startingDate.setDate(startingDate.getDate() - (startingDate.getDay() - 1));
  const dates = getDatesInMonth(new Date(startingDate), events);

  const [selectedEvent, setSelectedEvent] = useState(-1);

  return (
    <>
      {DAYS_SHORT.map((dayLabel: string, index: number) => {
        return (
          <Box mb={2} mt={2} key={index} className="days">
            <Typography align="right" fontWeight="bold">
              {dayLabel}
            </Typography>
          </Box>
        );
      })}

      <Grid container columns={7}>
        {dates.map((date, index) => {
          return (
            <Grid
              item
              xs={1}
              key={index}
              className={`cell 
                  ${isCurrentDay(date.date)} 
                  ${isCurrentMonth(date.date, selectedDate)} 
                  ${isMobile ? 'mobileEvent' : ''}`}
              sx={{ height: isMobile ? '80px' : '135px' }}
              onClick={() => (isMobile ? setDayViewDate(date) : '')}
            >
              <Typography align="right" pr={1}>
                {date.date.getDate()}
              </Typography>
              {isMobile ? (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  {date.events.length > 0 && (
                    <FiberManualRecordIcon color="primary" />
                  )}
                </Box>
              ) : (
                <Box className="miniEventContainer">
                  <MiniEvent
                    key={index}
                    events={date.events}
                    selectedEvent={selectedEvent}
                    setSelectedEvent={setSelectedEvent}
                  />
                </Box>
              )}
            </Grid>
          );
        })}
      </Grid>
    </>
  );
}
