import React, { useState, ReactNode } from 'react';
import { Button, Grid, Typography } from '@mui/material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EventOrGroupList from 'components/EventOrGroupList';

interface EventsOrGroupsPreviewProps {
  title: string | ReactNode;
  data: any[];
  type: string;
  disableShowMore: boolean;
}

function EventsOrGroupsPreview({
  title,
  data,
  type,
  disableShowMore,
}: EventsOrGroupsPreviewProps) {
  const [showMore, setShowMore] = useState(true);
  const [maxRangeOfArray, setMaxRangeOfArray] = useState<number>(3);

  const showMoreGroups = () => {
    if (showMore) {
      if (data.length <= maxRangeOfArray + 3) {
        setShowMore(false);
      } else {
        setMaxRangeOfArray(maxRangeOfArray + 3);
      }
    } else {
      setShowMore(true);
      setMaxRangeOfArray(3);
    }
  };

  const renderTitle = () => {
    if (typeof title === 'string') {
      return (
        <Typography variant="h3" marginBottom="0.5em" marginTop="1em">
          {title}
        </Typography>
      );
    }
    return title;
  };

  return (
    <>
      {renderTitle()}
      {data && data.length > 0 ? (
        <Grid xs={12} width={'inherit'} item>
          <Grid container item justifyContent="space-evenly" rowSpacing={4}>
            {showMore ? (
              <EventOrGroupList
                data={data?.filter((e, index) => index < maxRangeOfArray)}
              />
            ) : (
              <EventOrGroupList data={data} />
            )}
          </Grid>
          {data.length > 3 && !disableShowMore && (
            <Grid
              xs={12}
              container
              item
              justifyContent="center"
              marginBottom="1em"
            >
              <Button
                disableElevation
                endIcon={showMore ? <ExpandMoreIcon /> : <ExpandLessIcon />}
                style={{ width: 'fit-content' }}
                sx={{ color: 'primary.main', margin: 'auto' }}
                onClick={showMoreGroups}
              >
                <Typography fontWeight="bold">
                  {showMore ? `Show more ${type}` : `Show less ${type}`}
                </Typography>
              </Button>
            </Grid>
          )}
        </Grid>
      ) : (
        <Grid
          item
          container
          xs={12}
          direction="row"
          justifyContent="center"
          marginBottom="2em"
        >
          <Typography variant="h4">There are currently no {type}</Typography>
        </Grid>
      )}
    </>
  );
}

export default EventsOrGroupsPreview;
