import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import EventOrGroupList from 'components/EventOrGroupList';
import LoadingIcon from 'components/LoadingIcon';
import useHttpGet from 'hooks/useHttpGet';
import useUser from 'hooks/useUser';
import { useState } from 'react';

export default function ManageGroupsPage() {
  const { authorizedGroups } = useUser();

  // Load data
  const { data: groups, isLoading: isLoadingGroups } =
    useHttpGet<any[]>('/social-groups'); // Get by user location

  // Filter
  const [showPublished, setShowPublished] = useState(true);
  const [groupSearch, setGroupSearch] = useState('');
  const [selectedOrder, setSelectionOrder] = useState<string | number>(0);

  const filterGroups = (groups: any) => {
    /*
    let locationFilter = searchFilter.filter(
      (group: any) => group.city.toLowerCase() === location.toLowerCase()
      );
      */
    const filteredGroups = groups
      .filter((group: any) => authorizedGroups.includes(group.socialGroupId)) // Filter by what user is allowed to edit;
      .filter((group: any) => group.isPublished === showPublished) // Filter for same as selected visibility
      .filter(
        (group: any) =>
          group.name.toLowerCase().includes(groupSearch.toLowerCase()) ||
          group.description.toLowerCase().includes(groupSearch.toLowerCase())
      ); // Filter by search

    return selectedOrder === 1 ? filteredGroups.reverse() : filteredGroups;
  };

  if (isLoadingGroups) {
    return <LoadingIcon />;
  }

  return (
    <>
      <Grid container direction={'column'} gap={2}>
        <Typography variant="h1" marginTop={'1em'}>
          {showPublished ? 'Published Groups' : 'Hidden Groups'}
        </Typography>

        <Grid container direction="row" gap={2}>
          <Button
            disableElevation
            variant={showPublished ? 'contained' : 'text'}
            onClick={() => setShowPublished(true)}
            color="primary"
          >
            Published
          </Button>
          <Button
            disableElevation
            variant={!showPublished ? 'contained' : 'text'}
            onClick={() => setShowPublished(false)}
            color="primary"
          >
            Hidden
          </Button>
        </Grid>

        <Grid container gap={2} my={2}>
          <Grid item xs={12} sm={8} md={3}>
            <TextField
              fullWidth
              label="Search"
              minRows={1}
              value={groupSearch}
              onChange={(e) => setGroupSearch(e.target.value)}
            />
          </Grid>

          <Grid item xs={12} sm={8} md={3}>
            <FormControl fullWidth>
              <InputLabel id="orderLabel">Order</InputLabel>
              <Select
                labelId="orderLabel"
                label="Order"
                value={selectedOrder}
                onChange={(e) => setSelectionOrder(e.target.value)}
              >
                <MenuItem value={0}>Newest groups</MenuItem>
                <MenuItem value={1}>Oldest groups</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>

      {groups && <EventOrGroupList data={filterGroups(groups) || []} />}
    </>
  );
}
