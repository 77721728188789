import { Grid } from '@mui/material';
import AlertBox from 'components/AlertBox/AlertBox';
import useUser from 'hooks/useUser';
import { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { currentLocation } from 'atoms';
import { themeColorState, allCityColor } from 'atoms/themeState';
import InformationBanner from 'components/InformationBanner';
import InfoRowSection from './InfoRowSection';
import LocationCollection from './LocationCollection';
import LocationDialog from './LocationDialog';
import StaticInfoSection from './StaticInfoSection';
import './index.scss';

function Home() {
  const [, setThemeColor] = useRecoilState(themeColorState);
  const [, setCurLocation] = useRecoilState(currentLocation);

  const { location, isAuthenticated } = useUser();
  const [openDialog, setOpenDialog] = useState(false);

  const [searchParams] = useSearchParams();
  const [openAlertBox, setOpenAlertBox] = useState(false);
  const navigate = useNavigate();

  const handleAlertBox = () => {
    setOpenAlertBox(false);
    navigate('/');
  };

  useEffect(() => {
    setThemeColor(allCityColor);
    setCurLocation('base');
    if (location === '') {
      setOpenDialog(true);
    }
    if (
      searchParams.get('error_description') === 'Verify email before logging in'
    ) {
      setOpenAlertBox(true);
    }
  }, []);

  return (
    <>
      <Grid container justifyContent={'center'} gap={12}>
        {!isMobile && (
          <img alt={'Alt'} src={'/blue_line_large.svg'} className="blue-line" />
        )}
        <InformationBanner />
        <StaticInfoSection />
        <InfoRowSection />
        <LocationCollection />
        {openDialog && isAuthenticated && (
          <LocationDialog open={openDialog} setOpen={setOpenDialog} />
        )}
      </Grid>
      <AlertBox
        text={'Please verify your email'}
        handleClose={handleAlertBox}
        open={openAlertBox}
      />
    </>
  );
}

export default Home;
