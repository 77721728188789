import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Dialog,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import { dateToString } from 'components/CreateEventForm';
import EventOrGroupCard from 'components/EventOrGroupCard';
import LoadingIcon from 'components/LoadingIcon';
import { isMobile } from 'react-device-detect';
import '../../styles/eventpopup.scss';

function EventOrGroupPopup(props: {
  open: boolean;
  setOpen: any;
  formData: any;
  handleFormSubmit: any;
  previewUrl: string;
  waitPostRequest: boolean;
  isEvent: boolean;
  hostingGroupName?: string;
}) {
  const {
    open,
    setOpen,
    handleFormSubmit,
    formData,
    previewUrl,
    waitPostRequest,
    isEvent,
    hostingGroupName,
  } = props;

  return (
    <>
      <Dialog open={open}>
        <Grid container alignContent={'center'} direction={'column'}>
          <Card
            className="eventpopup-container"
            sx={{ width: isMobile ? '100%' : '500px' }}
          >
            <Grid item>
              <Box className="banner">
                <CardHeader
                  action={
                    <IconButton onClick={() => setOpen(false)}>
                      <CloseIcon sx={{ color: 'black' }} />
                    </IconButton>
                  }
                  className="icon"
                />
              </Box>
            </Grid>

            <Grid item>
              <CardContent className="popup-content-container">
                <Typography
                  variant="h5"
                  textAlign="center"
                  marginBottom="0.5em"
                >
                  Looking good!
                </Typography>
                {isEvent ? (
                  <Typography textAlign="center" marginBottom="0.5em">
                    If there is a cost related to the activity, it will need to
                    be approved by an administrator of the group.
                  </Typography>
                ) : (
                  <Typography textAlign="center" marginBottom="0.5em">
                    Group administrators will be able to control the group, as
                    well as activities related to it.
                  </Typography>
                )}

                {isEvent ? (
                  <EventOrGroupCard
                    {...{
                      data: {
                        eventId: -1,
                        title: formData.title,
                        description: formData.description,
                        imageUrl: previewUrl,
                        address: formData.address,
                        groupName: hostingGroupName ?? '',
                        startTime: dateToString(formData.startTime.local()),
                        endTime: dateToString(formData.endTime.local()),
                      },
                    }}
                  ></EventOrGroupCard>
                ) : (
                  <EventOrGroupCard
                    {...{
                      data: {
                        socialGroupId: -1,
                        name: formData.name,
                        description: formData.description,
                        imageUrl: previewUrl,
                      },
                    }}
                  ></EventOrGroupCard>
                )}

                <Grid
                  container
                  direction={'row'}
                  alignItems={'center'}
                  justifyContent={'center'}
                  paddingTop={'0.5em'}
                >
                  {waitPostRequest ? (
                    <LoadingIcon />
                  ) : (
                    <Grid item>
                      <Button
                        disableElevation
                        color="primary"
                        variant="contained"
                        onClick={handleFormSubmit}
                      >
                        Submit
                      </Button>
                      <Button
                        disableElevation
                        color="secondary"
                        variant="contained"
                        sx={{
                          marginLeft: '0.5rem',
                        }}
                        onClick={() => setOpen(false)}
                      >
                        Cancel
                      </Button>{' '}
                    </Grid>
                  )}
                </Grid>
              </CardContent>
            </Grid>
          </Card>
        </Grid>
      </Dialog>
    </>
  );
}

export default EventOrGroupPopup;
