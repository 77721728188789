import { Button, Card } from '@mui/material';
import GroupCard from 'components/GroupCard';
import useHttpGet from 'hooks/useHttpGet';
import useUser from 'hooks/useUser';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import Carousel from 'react-material-ui-carousel';
import { SocialEventOverview } from 'types';
import BigEvent from './BigEvent';
import './index.scss';

function ImageCarousel() {
  const handle = useFullScreenHandle();
  const { location } = useUser();
  const { data: eventArray } = useHttpGet<SocialEventOverview[]>(
    `/locations/${location}/social-spotlight`
  );

  return (
    <>
      <Button
        onClick={handle.enter}
        variant="contained"
        sx={{ marginBottom: '10px' }}
      >
        Enter fullscreen
      </Button>

      <FullScreen handle={handle}>
        <Carousel className="carousel">
          {(eventArray ?? []).map((event, i) => (
            <Item key={i} data={event} />
          ))}
        </Carousel>
      </FullScreen>
    </>
  );
}

function Item(props: { data: any }) {
  const { data } = props;
  return (
    <Card
      sx={{
        width: 1,
        height: '97vh',
        position: 'relative',
        boxShadow: '0px 4px 10px 0px rgb(0 0 0 / 4%)',
        borderRadius: '12px',
      }}
    >
      {data.socialGroupId ? <GroupCard {...data} /> : <BigEvent {...data} />}
    </Card>
    // <Box sx={{ width: '100%', height: '97vh', backgroundColor: 'green' }}>
    //   <Typography>Testt</Typography>
    // </Box>
    //<EventCard eventId={event.eventId} title={event.title} description={event.description} address={event.address} groupName={event.groupName}/>
    // <EventsOrGroupsPreview
    //   title="Upcoming Events"
    //   data={eventArray ?? []}
    //   type="events"
    //   disableShowMore={false}
    // />
  );
}

export default ImageCarousel;
