import { isMobile } from 'react-device-detect';
import SideNavDrawer, { MuiVariant } from './SideNavDrawer';

const SideNav = (props: {
  isOpen: boolean;
  setIsOpen: (prev: boolean) => void;
}) => {
  const { isOpen, setIsOpen } = props;
  const sideNavProps = {
    variant: (isMobile ? 'temporary' : 'permanent') as MuiVariant,
    onClose: isMobile ? () => setIsOpen(false) : undefined,
    isOpen: isMobile ? isOpen : true,
  };

  return <SideNavDrawer {...sideNavProps} />;
};

export default SideNav;
