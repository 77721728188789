import AddIcon from '@mui/icons-material/Add';
import { FormControl, Grid, MenuItem } from '@mui/material';
import Typography from '@mui/material/Typography';
import useUser from 'hooks/useUser';
import { useNavigate } from 'react-router-dom';

export default function EventMenu(props: { onClose?: () => void }) {
  const navigate = useNavigate();
  const { onClose } = props;
  const { isAuthenticated } = useUser();

  return (
    <>
      <FormControl
        fullWidth
        variant="filled"
        sx={{ minWidth: 120, marginLeft: '1.3em' }}
      >
        <MenuItem
          onClick={() => {
            onClose?.();
            navigate('/myattendances');
          }}
          sx={{ pl: 4 }}
          style={{
            color: 'white',
            justifyContent: 'flex-start',
            width: '100%',
          }}
        >
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid my="auto" item xs={8}>
              <Typography variant="body1">My attendances</Typography>
            </Grid>
          </Grid>
        </MenuItem>
        {isAuthenticated && (
          <MenuItem
            onClick={() => {
              onClose?.();
              navigate('/management/events/create');
            }}
            sx={{ pl: 4 }}
            style={{
              color: 'white',
              justifyContent: 'flex-start',
              width: '100%',
            }}
          >
            <AddIcon />
            <Typography marginLeft="1em">Create activity</Typography>
          </MenuItem>
        )}
      </FormControl>
    </>
  );
}
