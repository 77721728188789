import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, Button, Grid, Tooltip, Typography } from '@mui/material';
import UserAvatar from 'components/UserAvatar';
import UserSearch from 'components/UserSearch/UserSearch';
import { isValidInput } from 'helpers/inputValidator';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { EditSocialGroup, UserWithImage } from 'types';

function GroupAdminInfoForm(props: {
  groupFormState: EditSocialGroup;
  setGroupFormState: any;
  setHasErrorState: any;
}) {
  const { groupFormState, setGroupFormState, setHasErrorState } = props;
  const [user, setUser] = useState<UserWithImage | null>(null); // Controls DROPDOWN

  const { groupId } = useParams();

  const [userOptions, setUserOptions] = useState<UserWithImage[]>([]);
  useEffect(() => {
    setUserOptions(
      groupFormState.groupCoreMembers ? groupFormState.groupCoreMembers : []
    );
  }, [groupFormState.groupCoreMembers]);

  function selectGroupAdmin() {
    if (user) {
      setHasErrorState((prev: any) => ({
        ...prev,
        groupLeadMember: !isValidInput(user.appUserId),
      }));
      setGroupFormState((prev: EditSocialGroup) => ({
        ...prev,
        groupLeadMember: user,
      }));
      setUser(null);
    }
  }

  function removeGroupAdmin() {
    setHasErrorState((prev: any) => ({
      ...prev,
      groupLeadMember: true,
    }));
    setGroupFormState((prev: EditSocialGroup) => ({
      ...prev,
      groupLeadMember: null,
    }));
    setUser(null);
  }

  return (
    <>
      <Grid container direction={'column'} gap={2}>
        <Typography variant="h3">
          Group contact
          <Box component="span" color="red" ml={0.5}>
            *
          </Box>
        </Typography>

        <Grid container gap={2}>
          {!groupFormState.groupLeadMember ? (
            <React.Fragment>
              <Grid item xs={12} md={4}>
                <UserSearch
                  setUser={(user) => {
                    setUser(user);
                  }}
                  users={userOptions}
                />
              </Grid>
              {groupId && (
                <Grid item my="auto">
                  <Tooltip
                    title="Same privilege as group admin, but remains as the contact person for the group"
                    placement="right"
                  >
                    <InfoOutlinedIcon />
                  </Tooltip>
                </Grid>
              )}
              {user && (
                <Grid item xs={12} md={2}>
                  <Button
                    disableElevation
                    variant="contained"
                    className="create-event-button"
                    fullWidth
                    onClick={selectGroupAdmin}
                  >
                    Select
                  </Button>
                </Grid>
              )}
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Grid item xs={12} md={6}>
                <Grid container alignItems="center" marginBottom={1}>
                  <Grid item xs={3}>
                    <UserAvatar
                      fullName={groupFormState.groupLeadMember.fullName}
                      imageUrl={groupFormState.groupLeadMember.imageUrl || ''}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Typography>
                      {groupFormState.groupLeadMember.fullName}
                    </Typography>
                    <Typography>
                      {groupFormState.groupLeadMember.email}
                    </Typography>
                  </Grid>
                </Grid>
                <Button
                  disableElevation
                  variant="contained"
                  className="create-event-button"
                  fullWidth
                  onClick={removeGroupAdmin}
                >
                  Remove
                </Button>
              </Grid>
            </React.Fragment>
          )}
        </Grid>
      </Grid>
    </>
  );
}

export default GroupAdminInfoForm;
