import { CardMedia } from '@mui/material';
import './index.scss';

function BigCardImage(props: { imageUrl: string; isEvent: boolean }) {
  const { imageUrl, isEvent } = props;
  const imageHeight = '50vh';
  const imageWidth = '75vh';

  return (
    <div className="big-image-container">
      <CardMedia
        className="big-image"
        component="img"
        image={imageUrl}
        height={imageHeight}
        width={imageWidth}
        src="picture"
      ></CardMedia>
      <div className="big-eventcard-fader"></div>
    </div>
  );
}

export default BigCardImage;
