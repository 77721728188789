import moment from 'moment';
import { useEffect, useState } from 'react';
import { SocialEventCalendar } from 'types';
import CalendarView from './CalendarView';
import './index.scss';

export default function Calendar(props: {
  preloadedEvents: SocialEventCalendar[];
}) {
  const { preloadedEvents } = props;

  const currentMonth: number = moment().month(); // was static January
  const currentYear: number = moment().year(); // was static 2023
  const [selectedDate, setSelectedDate] = useState(
    new Date(currentYear, currentMonth)
  );

  useEffect(() => {
    // May need to get data monthly if there are too many events
  }, [selectedDate]);

  return (
    <>
      <div className="calendar">
        <CalendarView
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          events={preloadedEvents}
        />
      </div>
    </>
  );
}
