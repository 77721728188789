import { Box, Typography } from '@mui/material';

function ScheduleIntroduction() {
  return (
    <>
      <Typography variant="h1" marginTop={'1em'}>
        Schedule
      </Typography>
      <Typography variant="body1" marginBottom={'2em'}>
        Explore upcoming activities in{' '}
        <Box
          component="span"
          sx={{
            backgroundColor: 'primary.light',
            display: 'inline-block',
          }}
        >
          your location
        </Box>
      </Typography>
    </>
  );
}
export default ScheduleIntroduction;
