import { useAuth0 } from '@auth0/auth0-react';
import { Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import LoadingIcon from 'components/LoadingIcon';
import useHttpGet from 'hooks/useHttpGet';
import { isMobile } from 'react-device-detect';
import { useNavigate } from 'react-router';
import { LocationBase } from 'types';
import '../../styles/footer.scss';

function Footer() {
  const { isAuthenticated } = useAuth0();
  const navigate = useNavigate();
  const { logout, loginWithRedirect } = useAuth0();
  const { data: allLocations, isLoading: loadingLocations } =
    useHttpGet<LocationBase[]>('/locations');

  const ProfileLink = () => (
    <Typography gutterBottom>
      <span className="clickable-link" onClick={() => navigate(`/user`)}>
        Profile
      </span>
    </Typography>
  );
  const LogOutLink = (logoutFunction: any) => (
    <Typography gutterBottom>
      <span
        className="clickable-link"
        onClick={() => {
          logoutFunction({ returnTo: window.origin });
        }}
      >
        Log out
      </span>
    </Typography>
  );
  const LogInLink = (loginFunction: any) => (
    <Typography gutterBottom>
      <span
        className="clickable-link"
        onClick={() => {
          loginFunction();
        }}
      >
        Log in
      </span>
    </Typography>
  );
  const EventsLink = () => (
    <Typography gutterBottom>
      <span className="clickable-link" onClick={() => navigate(`/events`)}>
        Activities
      </span>
    </Typography>
  );
  const GroupsLink = () => (
    <Typography gutterBottom>
      <span className="clickable-link" onClick={() => navigate(`/groups`)}>
        Groups
      </span>
    </Typography>
  );
  const year = new Date().getFullYear();

  if (loadingLocations) {
    return <LoadingIcon />;
  }

  return (
    <>
      {isMobile ? (
        <Box className="footer-container">
          <Box sx={{ padding: '3em' }}>
            <Grid container direction="row" rowSpacing={2}>
              <Grid item sm={6} xs={12}>
                <Typography fontWeight={'bold'}>Your account</Typography>
                {isAuthenticated ? (
                  <>
                    {ProfileLink()}
                    {LogOutLink(logout)}
                  </>
                ) : (
                  <>{LogInLink(loginWithRedirect)}</>
                )}

                <Typography
                  gutterBottom
                  fontWeight={'bold'}
                  sx={{ marginTop: '1.5em' }}
                >
                  Discover
                </Typography>
                {EventsLink()}
                {GroupsLink()}
              </Grid>
              <Grid item sm={6} xs={12}>
                <Typography fontWeight={'bold'} gutterBottom>
                  Locations
                </Typography>
                {allLocations?.map((location: any, idx: number) => {
                  return (
                    <Typography
                      key={idx}
                      sx={{ display: 'inline-block', width: '50%' }}
                      gutterBottom
                    >
                      <span
                        className="clickable-link"
                        onClick={() => navigate(`/locations/${location.city}`)}
                      >
                        {location.city}
                      </span>
                    </Typography>
                  );
                })}
              </Grid>
            </Grid>
          </Box>
          <Typography
            align="center"
            sx={{
              paddingBottom: '3em',
            }}
          >
            &copy; {year} CapSocial
          </Typography>
        </Box>
      ) : (
        <Box
          className={
            isAuthenticated ? 'footer-container-small' : 'footer-container'
          }
        >
          <Box
            sx={{
              width: '60%',
              paddingTop: '3em',
              paddingRight: '3em',
              paddingBottom: '1.5em',
              paddingLeft: isAuthenticated ? '3em' : '9em',
            }}
          >
            <Grid container direction="row">
              <Grid item xs={3}>
                <Typography fontWeight={'bold'} gutterBottom>
                  Your account
                </Typography>
                {isAuthenticated ? (
                  <>
                    {ProfileLink()}
                    {LogOutLink(logout)}
                  </>
                ) : (
                  <>{LogInLink(loginWithRedirect)}</>
                )}
              </Grid>
              <Grid item xs={3}>
                <Typography gutterBottom fontWeight={'bold'}>
                  Discover
                </Typography>
                {EventsLink()}
                {GroupsLink()}
              </Grid>
              <Grid item xs={6}>
                <>
                  <Typography fontWeight={'bold'} gutterBottom>
                    Locations
                  </Typography>
                  {allLocations?.map((location: any, idx: number) => {
                    return (
                      <Typography
                        key={idx}
                        sx={{ display: 'inline-block', width: '50%' }}
                        gutterBottom
                      >
                        <span
                          className="clickable-link"
                          onClick={() =>
                            navigate(`/locations/${location.city}`)
                          }
                        >
                          {location.city}
                        </span>
                      </Typography>
                    );
                  })}
                </>
              </Grid>
            </Grid>
          </Box>
          <Typography
            sx={{
              paddingLeft: isAuthenticated ? '3em' : '9em',
              paddingBottom: '1.5em',
            }}
          >
            &copy; {year} CapSocial
          </Typography>
          <img alt={'Alt'} src={'/blue_line.svg'} className="footer-line" />
        </Box>
      )}
    </>
  );
}

export default Footer;
