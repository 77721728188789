import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Button, Grid, Typography } from '@mui/material';
import LoadingIcon from 'components/LoadingIcon';
import { useState } from 'react';
import { isMobile } from 'react-device-detect';
import MemberCard, { MemberCardInterface } from './MemberCard';

function MemberOverview(props: {
  members: MemberCardInterface[];
  isLoading: boolean;
}) {
  const [showMore, setShowMore] = useState(true);
  const [maxRangeOfArray, setMaxRangeOfArray] = useState<number>(
    isMobile ? 6 : 12
  );

  const { members, isLoading } = props;

  const userlength = members?.length || 0;

  const showMoreMembers = () => {
    if (showMore) {
      if (
        isMobile
          ? maxRangeOfArray >= userlength - 6
          : maxRangeOfArray >= userlength - 12
      ) {
        setShowMore(false);
      }
      setMaxRangeOfArray(isMobile ? maxRangeOfArray + 6 : maxRangeOfArray + 12);
    } else {
      setShowMore(true);
      setMaxRangeOfArray(isMobile ? 6 : 12);
    }
  };

  if (isLoading) {
    return (
      <Grid item padding={'4.2rem 0'}>
        <LoadingIcon />
      </Grid>
    );
  }

  if (!userlength) {
    return (
      <Grid container justifyContent={'center'} direction="column">
        <Grid item xs={12} container justifyContent={'center'}>
          <Typography variant="h4"> Be the first to join!</Typography>
        </Grid>
      </Grid>
    );
  }

  return (
    <>
      <Grid xs={12} item container justifyContent={'space-evenly'}>
        {members
          .filter((_, index) => index < maxRangeOfArray)
          .map((member, index) => (
            <MemberCard key={index} user={member} />
          ))}
      </Grid>
      {(userlength > 12 || (userlength > 6 && isMobile)) && (
        <Button
          disableElevation
          endIcon={showMore ? <ExpandMoreIcon /> : <ExpandLessIcon />}
          style={{ width: 'fit-content' }}
          sx={{ color: 'primary.main', margin: 'auto' }}
          onClick={showMoreMembers}
        >
          <Typography fontWeight="bold">
            {showMore ? 'Show more members' : 'Show fewer members'}
          </Typography>
        </Button>
      )}
    </>
  );
}

export default MemberOverview;
