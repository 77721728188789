import type { MessagePayload } from 'firebase/messaging';
import { isDev } from 'api/common';
type AppEvent<T> = {
  timestamp: Date;
  payload: T;
};

type AppEventHandler<T extends AppEvent<any>> = (
  event: T
) => void | Promise<void>;

type SubscribeOptions = {
  once: boolean;
};

type HandlerEntry<T extends AppEvent<any>> = {
  id: string;
  options: SubscribeOptions;
  handler: AppEventHandler<T>;
};

class Publisher<TEvent extends AppEvent<any>> {
  private events = [] as TEvent[];
  private handlers = new Map<string, HandlerEntry<TEvent>>();

  subscribe(
    id: string,
    handler: AppEventHandler<TEvent>,
    options: SubscribeOptions = { once: false }
  ) {
    if (isDev && this.handlers.has(id))
      console.warn(
        `Handler with id ${id} already exists and will be overwritten`
      );
    this.handlers.set(id, { id, options, handler });
  }

  subscribeLatest(
    id: string,
    handler: AppEventHandler<TEvent>,
    options: SubscribeOptions = { once: false }
  ) {
    if (!this.events.length || !options.once) {
      this.subscribe(id, handler, options);
    }

    if (this.events.length) {
      void handler(this.events[this.events.length - 1]);
    }
  }

  unsubscribe(id: string) {
    this.handlers.delete(id);
  }

  publish(event: TEvent) {
    Object.freeze(event);
    this.events.push(event);
    this.consume(event);
  }

  private consume(event: TEvent) {
    this.handlers.forEach((entry) => {
      void entry.handler(event);
      if (entry.options.once) {
        this.unsubscribe(entry.id);
      }
    });
  }
}

export type FCMPushRecieved = AppEvent<MessagePayload>;
export const pushRecievedPublisher = new Publisher<FCMPushRecieved>();

export type NewSwActivated = AppEvent<ServiceWorkerRegistration>;
export const swActivatedPublisher = new Publisher<NewSwActivated>();
