import { useRecoilState } from 'recoil';
import { currentUserState } from 'atoms';

const useUser = () => {
  const [currentUser, setCurrentUser] = useRecoilState(currentUserState);
  return {
    ...currentUser,
    setCurrentUser,
  };
};

export default useUser;
