import { Grid, Typography } from '@mui/material';
import { SocialEventForm } from 'types';

function CostInfoForm(props: {
  eventFormState: SocialEventForm;
  setEventFormState: any;
  hasErrorState: any;
  setHasErrorState: any;
}) {
  const { eventFormState, setEventFormState } = props;

  /*
  const [hasCost, setHasCost] = useState<boolean>(false);
  useEffect(() => {
    if (eventFormState.cost > 0) {
      setHasCost(true);
    }
  }, [eventFormState.cost]);
  const handleCostChange = () => {
    if (hasCost) {
      setEventFormState((prev: SocialEventForm) => ({
        ...prev,
        cost: 0,
      }));
    }
    setHasCost((prev) => !prev);
  };
  */

  return (
    <Grid container item gap={2}>
      <Typography variant="h3">Cost</Typography>
      <Grid container alignItems="center" gap={2}>
        <Grid item xs={12}>
          <Typography>
            If there is a cost that Capgemini covers, please contact the group
            administrator before creating the activity.
          </Typography>
        </Grid>
        {/*
        {hasCost && (
          <Grid item xs={12} sm={3}>
            <TextField
              fullWidth
              label={'Cost'}
              type={'number'}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">kr</InputAdornment>
                ),
              }}
              value={eventFormState.cost ?? 0}
              onChange={(e) => {
                setEventFormState((prev: any) => ({
                  ...prev,
                  cost: parseInt(e.target.value),
                }));
              }}
            />
          </Grid>
        )}
         */}
      </Grid>
    </Grid>
  );
}

export default CostInfoForm;
