import { Divider, Grid, TextField, Typography } from '@mui/material';
import EventOrGroupList from 'components/EventOrGroupList';
import EventsOrGroupsPreview from 'components/EventsOrGroupsPreview';
import LoadingIcon from 'components/LoadingIcon';
import useHttpGet from 'hooks/useHttpGet';
import useUser from 'hooks/useUser';
import GroupSelect from 'pages/AllEvents/GroupSelect';
import { Fragment, useState } from 'react';
import { SocialEventOverview } from 'types';

export default function ManagePastEventsPage() {
  const { location, authorizedEvents } = useUser();
  const [sortDataBy, setSortDataBy] = useState('Date');
  const [searchInput, setSearchInput] = useState('');

  const { data: pastEvents, isLoading: isLoadingEvents } = useHttpGet<
    SocialEventOverview[]
  >(`/locations/${location}/past-events`);

  const groupEventsByGroupName = (data: any) => {
    const groupedEvents = data.reduce((groups: any, event: any) => {
      const group = groups[event.groupName] || [];
      group.push(event);
      groups[event.groupName] = group;
      return groups;
    }, {});
    return groupedEvents;
  };

  const handleSearchChange = (e: any) => {
    setSearchInput(e.target.value);
  };

  const handleFilter = (data: any, filterInput: string) => {
    return (data ?? [])
      .filter((event: any) => authorizedEvents.includes(event.eventId))
      .filter(
        (o: any) =>
          !filterInput ||
          o.title?.toLowerCase().includes(filterInput.toLowerCase()) ||
          o.description?.toLowerCase().includes(filterInput.toLowerCase()) ||
          o.groupName?.toLowerCase().includes(filterInput.toLowerCase()) ||
          o.name?.toLowerCase().includes(filterInput.toLowerCase())
      );
  };

  if (isLoadingEvents) {
    return <LoadingIcon />;
  }

  return (
    <>
      <Grid container direction={'column'} gap={2}>
        <Typography variant="h1" marginTop={'1em'}>
          Past activities
        </Typography>
        <Typography variant="body1" marginBottom={'2em'}>
          Archive of previously occured activities
        </Typography>
      </Grid>

      <Grid container item gap={2} my={1} mb={3}>
        <Grid item xs={12} md={3}>
          <TextField
            fullWidth
            label="Search"
            minRows={1}
            onChange={handleSearchChange}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <GroupSelect sortDataBy={sortDataBy} setSortDataBy={setSortDataBy} />
        </Grid>
      </Grid>
      <Divider />

      {sortDataBy === 'Group' ? (
        <>
          {Object.keys(
            groupEventsByGroupName(handleFilter(pastEvents, searchInput) ?? [])
          ).map((key: any, index: number) => {
            return (
              <Fragment key={index}>
                <EventsOrGroupsPreview
                  title={key}
                  data={
                    groupEventsByGroupName(
                      handleFilter(pastEvents, searchInput) ?? []
                    )[key]
                  }
                  type="activities"
                  disableShowMore={false}
                />
                <Divider />
              </Fragment>
            );
          })}
        </>
      ) : (
        <>
          {pastEvents && (
            <EventOrGroupList
              data={handleFilter(pastEvents, searchInput) || []}
            />
          )}
        </>
      )}
    </>
  );
}
