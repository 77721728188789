import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import Grid from '@mui/material/Grid';

export default function AlertBox(props: {
  text: string;
  handleClose: () => void;
  open: boolean;
}) {
  const { text, handleClose, open } = props;

  return (
    <Dialog open={open}>
      <Grid container justifyContent="center">
        <DialogTitle>{text}</DialogTitle>
      </Grid>
      <DialogContent>
        <Grid container justifyContent={'center'}>
          <DialogContentText padding={0}></DialogContentText>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid
          container
          item
          xs={12}
          justifyContent="space-evenly"
          paddingBottom={'1rem'}
        >
          <Button
            disableElevation
            variant="contained"
            color="secondary"
            sx={{ color: 'white' }}
            onClick={handleClose}
            autoFocus
          >
            Close
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
