import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Button, Grid, Typography } from '@mui/material';
import ChatBox from 'components/ChatBox';
import EventsOrGroupsPreview from 'components/EventsOrGroupsPreview';
import LoadingIcon from 'components/LoadingIcon';
import MemberOverview from 'components/MemberOverview';
import { MemberCardInterface } from 'components/MemberOverview/MemberCard';
import { isAuthorizedGroup } from 'helpers/AuthorizedPage';
import useHttpGet from 'hooks/useHttpGet';
import useUser from 'hooks/useUser';
import { useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { useNavigate, useParams } from 'react-router';
import Banner from './Banner';
import GroupButtons from './GroupButtons';
import ImageStepper from 'components/ImageStepper';

function GroupPage() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { userId, isAuthenticated, authorizedGroups, isAdmin } = useUser();

  const {
    data: group,
    isLoading: loadingGroup,
    error,
    reload: reloadGroup,
  } = useHttpGet<any>(`/social-groups/${id}/overview`);
  const { data: location } = useHttpGet<any>(`/social-groups/${id}/city`);

  const {
    data: isMember,
    isLoading: loadMember,
    reload: reloadIsMember,
  } = useHttpGet<boolean>(`/memberships/${userId}/${id}/member`);

  const { data: events } = useHttpGet<any>(
    `/events/upcomingEventsForGroup/${id}`
  );

  const {
    data: users,
    isLoading: isLoadingMembers,
    reload: reloadMembers,
  } = useHttpGet<MemberCardInterface[]>(`/social-groups/${id}/users`);

  const { data: groups } = useHttpGet<any>(
    `social-groups/${id}/other-groups-in-location`
  );

  const { data: carouselData } = useHttpGet<any>(
    `/blobstorage/socialGroup-Carousel-imgurl/${id}`
  );

  const reloadData = () => {
    reloadGroup();
    reloadIsMember();
    reloadMembers();
  };

  useEffect(() => {
    if (error) navigate('/page-not-found');
  }, [error]);

  useEffect(() => {
    if (group === undefined) return;
    if (!authorizedGroups.length && isAuthenticated) return;
    if (isAuthorizedGroup(id ?? '', authorizedGroups) || group?.isPublished)
      return;
    navigate('/');
  }, [group, authorizedGroups]);

  if (loadingGroup || loadMember) {
    return <LoadingIcon />;
  }

  return (
    <>
      {isMobile && (
        <Button
          variant="outlined"
          color="primary"
          onClick={() => navigate(-1)}
          startIcon={<ArrowBackIosIcon />}
        >
          Go back
        </Button>
      )}
      <Grid
        container
        gap={4}
        direction="column"
        justifyContent="center"
        item
        xs={12}
        style={{
          alignItems: isMobile ? 'center' : '',
        }}
      >
        <Banner
          {...group}
          isMember={isMember}
          reloadData={reloadData}
          groupLocation={location}
        />
        {isAuthenticated && (
          <GroupButtons
            isMember={isMember}
            authorizedGroups={authorizedGroups}
            isAdmin={isAdmin}
            reloadData={reloadData}
            isPublished={group?.isPublished}
          />
        )}

        <ImageStepper carouselData={carouselData} />

        <EventsOrGroupsPreview
          title="Upcoming activities"
          data={events}
          type="activities"
          disableShowMore={false}
        />
        {isAuthenticated && (
          <>
            <ChatBox roomId={`group-${id}`} />
            <Grid xs={12} item>
              <Typography
                variant="h3"
                sx={{
                  width: 'fit-content',
                  margin: isMobile ? '0 auto' : '0',
                }}
              >
                Members
              </Typography>
            </Grid>
            <MemberOverview
              members={users ?? []}
              isLoading={isLoadingMembers}
            />
          </>
        )}
        <EventsOrGroupsPreview
          title={`Other groups ${location ? `in ${location.city}` : ''}`}
          data={groups ? groups : []}
          type="groups"
          disableShowMore={false}
        />
      </Grid>
    </>
  );
}

export default GroupPage;
