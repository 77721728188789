import { Card, Grid } from '@mui/material';
import GroupCard from 'components/GroupCard';
import moment from 'moment';
import './index.scss';
import EventCardPreview from 'components/EventCard/EventCardPreview';
import EventCard from 'components/EventCard';
import GroupCardPreview from 'components/GroupCard/GroupCardPreview';

function EventOrGroupCard(props: { data: any }) {
  const { data } = props;
  return (
    <Grid container item justifyContent="center" px={1}>
      <Card
        className="card"
        sx={{
          width: '100%',
          height: 415,
          position: 'relative',
          boxShadow: '0px 4px 10px 0px rgb(0 0 0 / 4%)',
          borderRadius: '12px',
        }}
      >
        {data.socialGroupId ? (
          data.socialGroupId === -1 ? (
            <GroupCardPreview {...data} />
          ) : (
            <GroupCard {...data} />
          )
        ) : data.eventId === -1 ? (
          <EventCardPreview
            {...data}
            startTime={moment(data.startTime)}
            endTime={moment(data.endTime)}
            deadline={data.deadline ? moment(data.deadline) : undefined}
            startAttendance={
              data.startAttendance ? moment(data.startAttendance) : undefined
            }
            isAttending={data.isAttending}
          />
        ) : (
          <EventCard
            {...data}
            startTime={moment(data.startTime)}
            endTime={moment(data.endTime)}
            deadline={data.deadline ? moment(data.deadline) : undefined}
            startAttendance={
              data.startAttendance ? moment(data.startAttendance) : undefined
            }
            isAttending={data.isAttending}
          />
        )}
      </Card>
    </Grid>
  );
}

export default EventOrGroupCard;
