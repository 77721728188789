import EventOrGroupOverview from 'components/EventOrGroupOverview';
import LoadingIcon from 'components/LoadingIcon';
import useHttpGet from 'hooks/useHttpGet';

function AllEvents() {
  const { data: socialEvents, isLoading } = useHttpGet<any>(
    '/events/upcoming-by-city'
  );

  return (
    <>
      {isLoading && !socialEvents ? (
        <LoadingIcon />
      ) : (
        <EventOrGroupOverview data={socialEvents} dataType={'Activities'} />
      )}
    </>
  );
}

export default AllEvents;
