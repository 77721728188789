import { Grid, Typography } from '@mui/material';
// import InfoBox from 'components/InfoBox/InfoBox';

const description1 = `See all the exciting activities going on around Capgemini here in Norway`;
const description2 = `Try out a new activity or join others who have similar interests as you`;
const description3 = `Have a great idea for a social event? Just create an activity and invite others to join!`;

const infoList = [
  { body: description1, title: 'Get an overview', icon: '/Icon_Overview.svg' },
  {
    body: description2,
    title: 'Search groups and activities',
    icon: '/Icon_Search_Groups.svg',
  },
  {
    body: description3,
    title: 'Create your own',
    icon: '/Icon_Create_Own.svg',
  },
];

function InfoRowSection() {
  return (
    <Grid container item justifyContent={'center'} gap={6} md={12} sm={8}>
      {infoList.map((info, i) => (
        <Grid key={i} item sm>
          <Grid
            container
            direction="column"
            justifyContent={'center'}
            alignItems={'center'}
            gap={2}
          >
            <img
              alt={info.icon}
              src={info.icon}
              style={{ marginLeft: 5 }}
              height={'73px'}
            />
            <Typography variant="h5" textAlign="center" fontWeight="bold">
              {info.title}
            </Typography>
            <Typography
              textAlign={'center'}
              color="text.secondary"
              maxWidth="213px"
            >
              {info.body}
            </Typography>
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
}

export default InfoRowSection;
