const isAuthorizedEvent = (eventId: number | string, events: number[]) => {
  if (typeof eventId === 'string') {
    eventId = parseInt(eventId);
  }
  return events?.includes(eventId) ?? [];
};

const isAuthorizedGroup = (groupId: number | string, groups: number[]) => {
  return groups?.includes(parseInt(groupId.toString())) ?? [];
};

export { isAuthorizedEvent, isAuthorizedGroup };
