import AccessTimeIcon from '@mui/icons-material/AccessTime';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { Box, Button, Grid } from '@mui/material';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import CardImage from 'components/EventOrGroupCard/CardImage';
import GetImageUrl from 'helpers/GetImage';
import { useNavigate } from 'react-router';
import '../EventOrGroupCard/index.scss';
import { Interweave } from 'interweave';
import { CardInterface } from 'types';

function TextHelper(
  text: string,
  format: {
    className?: string;
    fontSize?: number;
    fontWeight?: number;
    color?: string;
    lineHeight?: number;
    paddingBottom?: number;
  }
) {
  return <Typography {...format}>{text}</Typography>;
}

function EventCard(props: CardInterface) {
  const {
    eventId,
    title,
    description,
    imageUrl,
    address,
    groupName,
    startTime,
    endTime,
    isAttending,
  } = props;

  const navigate = useNavigate();
  return (
    <>
      <Box onClick={() => navigate('/events/' + eventId)} className="eventCard">
        <CardImage
          imageUrl={eventId === -1 ? imageUrl : GetImageUrl(imageUrl)}
          title={title}
          year={startTime.format('YYYY')}
          isEvent={true}
          isAttending={isAttending}
        />
        <CardContent>
          <Grid container direction="row" marginBottom="10px">
            <Box
              sx={{
                width: '18%',
                paddingRight: '4%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {TextHelper(startTime.format('D'), {
                fontSize: 30,
                fontWeight: 600,
                lineHeight: 1,
              })}
              {TextHelper(startTime.format('MMM'), {
                fontSize: 17,
                fontWeight: 600,
              })}
            </Box>
            <Box
              sx={{
                width: '70%',
                paddingLeft: '4%',
                borderLeft: '1.5px solid black',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {TextHelper(groupName, {
                className: 'groupName',
                fontWeight: 600,
                color: 'primary',
              })}
              <Box className="center-content" height="20px">
                <AccessTimeIcon className="icon-size" />
                {TextHelper(
                  `${startTime.format('HH:mm')} - ${endTime.format('HH:mm')}`,
                  {
                    fontSize: 14,
                  }
                )}
              </Box>
              <Box className="center-content" height="30px">
                <LocationOnIcon className="icon-size" />
                <Typography fontSize={14} lineHeight={1} maxWidth={'0px'}>
                  {address}
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Typography
            className="description-field"
            variant="body1"
            height={75}
            sx={{ whiteSpace: 'pre-line', wordBreak: 'break-word' }}
          >
            <Interweave content={description} />
          </Typography>
        </CardContent>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            position: 'absolute',
            bottom: '10px',
            left: '0',
            right: '0',
          }}
        >
          <Button
            disableElevation
            disabled={eventId === -1 ? true : false}
            color="primary"
            variant="outlined"
            onClick={() => navigate('/events/' + eventId)}
            sx={{
              width: '90%',
            }}
          >
            Learn more
          </Button>
        </Box>
      </Box>
    </>
  );
}

export default EventCard;
