import AccessTimeIcon from '@mui/icons-material/AccessTimeOutlined';
import DateRangeIcon from '@mui/icons-material/DateRangeOutlined';
import LocationOnIcon from '@mui/icons-material/LocationOnOutlined';
import { Box, Grid } from '@mui/material';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import GetImageUrl from 'helpers/GetImage';
import moment from 'moment';
import '../EventOrGroupCard/index.scss';
import BigCardImage from './BigCardImage';
import { CardInterface } from 'types';

function TextHelper(
  text: string,
  format: {
    className?: string;
    fontSize?: number;
    fontWeight?: number;
    color?: string;
    lineHeight?: number;
    paddingBottom?: number;
  }
) {
  return <Typography {...format}>{text}</Typography>;
}

function BigEvent(props: CardInterface) {
  const {
    eventId,
    title,
    description,
    imageUrl,
    address,
    groupName,
    startTime,
    endTime,
  } = props;

  return (
    <>
      <Grid container direction="row">
        <Grid
          container
          item
          direction="column"
          xs={12}
          md={6}
          sx={{
            maxWidth: '250px',
            maxHeight: '75px',
            padding: '40px 0px 100px 40px',
          }}
        >
          <CardContent>
            <Grid
              container
              sx={{
                marginBottom: '100px',
              }}
            >
              <Grid item>
                <Box
                  component="img"
                  sx={{
                    maxWidth: '250px',
                    maxHeight: '75px',
                    paddingBottom: '100px',
                  }}
                  alt="Logo"
                  src={`/CapSocialLogo_base.svg`}
                />
              </Grid>
              <Grid item xs={12}>
                {TextHelper(groupName, {
                  fontSize: 45,
                  fontWeight: 600,
                  color: 'primary',
                })}
              </Grid>
              <Grid item xs={12}>
                {TextHelper(title, {
                  fontSize: 60,
                  fontWeight: 600,
                  lineHeight: 1,
                  paddingBottom: 5,
                })}
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  height: '9.375em',
                }}
              >
                {TextHelper(description, {
                  fontSize: 25,
                  fontWeight: 500,
                  lineHeight: 1.2,
                })}
              </Grid>
            </Grid>
            <div className="line">
              <hr></hr>
            </div>
            <Grid container sx={{ marginTop: '100px', marginRight: '50px' }}>
              <Grid container direction="row">
                <Grid
                  item
                  sx={{
                    margin: 'auto',
                    marginLeft: '0px',
                    marginRight: '10px',
                  }}
                >
                  <LocationOnIcon color="primary" fontSize="large" />
                </Grid>
                <Grid item>
                  {TextHelper(address, { fontSize: 45, lineHeight: 1.5 })}
                </Grid>
              </Grid>
              <Grid container direction="row">
                <Grid
                  item
                  sx={{
                    margin: 'auto',
                    marginLeft: '0px',
                    marginRight: '10px',
                  }}
                >
                  <DateRangeIcon color="primary" fontSize="large" />
                </Grid>
                <Grid
                  item
                  sx={{
                    marginRight: '10px',
                  }}
                >
                  {TextHelper(moment(startTime).format('D'), {
                    fontSize: 45,
                    fontWeight: 500,
                    lineHeight: 1.5,
                  })}
                </Grid>
                <Grid item>
                  {TextHelper(moment(startTime).format('MMM'), {
                    fontSize: 45,
                    fontWeight: 500,
                  })}
                </Grid>
              </Grid>
              <Grid container direction="row">
                <Grid
                  item
                  sx={{
                    margin: 'auto',
                    marginLeft: '0px',
                    marginRight: '10px',
                  }}
                >
                  <AccessTimeIcon color="primary" fontSize="large" />
                </Grid>
                <Grid item>
                  {TextHelper(
                    `${moment(startTime).format('HH:mm')} - ${moment(
                      endTime
                    ).format('HH:mm')}`,
                    {
                      fontSize: 45,
                      lineHeight: 1.5,
                    }
                  )}
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Grid>
        <Grid container item xs={12} md={6}>
          <Box className="LocationBox" sx={{ backgroundColor: 'primary.main' }}>
            {''}
          </Box>
          <BigCardImage
            imageUrl={eventId === -1 ? imageUrl : GetImageUrl(imageUrl)}
            isEvent={true}
          />
          <div className="darkgreyBox"> </div>
          <div className="big-blueLine">
            <img alt={'Alt'} src={'/blue_line_large.svg'} />
          </div>
        </Grid>
      </Grid>
    </>
  );
}

export default BigEvent;
