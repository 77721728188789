import FileUploadIcon from '@mui/icons-material/FileUpload';
import { Button } from '@mui/material';

function ChooseFile(props: { fileSelectedHandler: any }) {
  const { fileSelectedHandler } = props;
  return (
    <>
      <input
        type="file"
        id="button-file"
        hidden
        accept="image/png, image/jpeg, image/jpg"
        onChange={fileSelectedHandler}
      ></input>

      <label htmlFor="button-file">
        <Button
          disableElevation
          variant="outlined"
          component="span"
          sx={{
            width: '100%',
            color: ' #828282',
            borderColor: ' #828282',
          }}
        >
          <FileUploadIcon />
          Upload a file
        </Button>
      </label>
    </>
  );
}
export default ChooseFile;
