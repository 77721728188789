import { Box, Grid, Typography } from '@mui/material';
import ProfilePicture from 'components/UserProfile/ProfilePicture';
import { isMobile } from 'react-device-detect';

function ImageAndIntro(props: {
  name: string;
  fileSelectedHandler: any;
  file: any;
  isEditing: boolean;
}) {
  const { name, file, fileSelectedHandler, isEditing } = props;

  return (
    <>
      {isMobile ? (
        <Grid container direction={'column'} alignItems="center" gap={2}>
          <Grid item>
            <Typography variant="h3">Hello, {name}!</Typography>
          </Grid>
          <Grid item>
            <ProfilePicture
              fileSelectedHandler={fileSelectedHandler}
              file={file}
              isEditing={isEditing}
            />
          </Grid>
          <Grid item>
            <Typography>
              Here you can change your profile name, image and location if you
              need to.
            </Typography>
          </Grid>
        </Grid>
      ) : (
        <Grid
          container
          direction={'row'}
          justifyContent="center"
          alignItems="center"
          gap={5}
          marginTop={'1em'}
        >
          <Grid item>
            <ProfilePicture
              fileSelectedHandler={fileSelectedHandler}
              file={file}
              isEditing={isEditing}
            />
          </Grid>
          <Grid item>
            <Box sx={{ width: '400px' }}>
              <Typography variant="h3">Hello, {name}!</Typography>
              <Typography>
                Here you can change your profile name, image and location if you
                need to.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      )}
    </>
  );
}

export default ImageAndIntro;
