function isValidInput(input: any): boolean {
  return (input === 0 || input?.length !== 0) ?? false;
}

function isValidDescription(input: string): boolean {
  // Check if the input contains a h1-h3 or span element
  var isHTML = RegExp.prototype.test.bind(/<(h[1-6]|span)[^>]*>/i);
  var isStyled = RegExp.prototype.test.bind(/style=(".*?"|'.*?'|[^"'][^\i]*)/i);
  if (isStyled(input) || isHTML(input)) {
    return false;
  }
  if (input.replace('<br>', '').length > 0 && input.length <= 1500) {
    return true;
  }
  return false;
}

function isValidEmail(input: string): boolean {
  const emailRegex = new RegExp(
    '^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$'
  );
  return emailRegex.test(input);
}

function isStrongPassword(input: string): boolean {
  const passwordRegex = new RegExp(
    '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_.])(?=.{8,})'
  );
  return passwordRegex.test(input);
}

function isValidName(input: string): boolean {
  const nameRegex = new RegExp('^[a-zA-ZæøåÆØÅ-]+$');
  const isFullName =
    input.split(' ').length >= 2 &&
    input.split(' ').length <= 4 &&
    input.length < 50;

  return input.split(' ').every((name) => nameRegex.test(name)) && isFullName;
}

function isContainingValue(input: string, values: string[]): boolean {
  return values
    .map((value) => value?.toLowerCase())
    .includes(input?.toLowerCase());
}

const isAllValid = (formState: Object, errorState: Object, exclude = '') => {
  const entries = Object.entries(formState).filter(
    (value) => value[0] !== exclude
  );
  return (
    entries.every(([_, value]) => isValidInput(value)) &&
    Object.entries(errorState).every(([_, value]) => value === false)
  );
};

export {
  isAllValid,
  isContainingValue,
  isStrongPassword,
  isValidEmail,
  isValidInput,
  isValidName,
  isValidDescription,
};
