import {
  FormControl,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { isContainingValue } from 'helpers/inputValidator';
import useHttpGet from 'hooks/useHttpGet';
import useUser from 'hooks/useUser';
import { useParams } from 'react-router';
import { SocialEventForm, SocialGroupBase } from 'types';

function SocialGroupSelect(props: {
  eventFormState: SocialEventForm;
  setEventFormState: any;
  hasErrorState: any;
  setHasErrorState: any;
  setHostingGroupName: any;
}) {
  const { location } = useUser();

  const {
    eventFormState,
    setEventFormState,
    hasErrorState,
    setHasErrorState,
    setHostingGroupName,
  } = props;

  const { data: allGroups } = useHttpGet<SocialGroupBase[]>(
    `/locations/${location}/social-groups`
  );
  const { eventId } = useParams();
  const handleChange = (e: SelectChangeEvent) => {
    setHasErrorState((prev: any) => ({
      ...prev,
      socialGroup: !isContainingValue(
        e.target.value.toString(),
        (allGroups || []).map((g: SocialGroupBase) =>
          g.socialGroupId.toString()
        )
      ),
    }));
    setEventFormState((prev: SocialEventForm) => ({
      ...prev,
      hostingGroups: [e.target.value],
    }));
    setHostingGroupName(
      allGroups?.filter((g) => g.socialGroupId === parseInt(e.target.value))[0]
        .name
    );
  };

  const capatelizeWord = (word: string) => {
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  };

  return (
    <>
      <FormControl fullWidth>
        <InputLabel id="social-group-select">Social Group</InputLabel>
        <Select
          disabled={!!eventId}
          required
          error={hasErrorState.socialGroup}
          labelId="social-group-select"
          defaultValue=""
          value={eventFormState.hostingGroups[0]?.socialGroupId?.toString()}
          label="Social Groups"
          onChange={handleChange}
        >
          <ListSubheader>{capatelizeWord(location)}</ListSubheader>
          {allGroups?.map((group) => (
            <MenuItem key={group.socialGroupId} value={group.socialGroupId}>
              {group.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
}

export default SocialGroupSelect;
