import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

function GroupSelect(props: { sortDataBy: any; setSortDataBy: any }) {
  const { sortDataBy, setSortDataBy } = props;

  return (
    <>
      <FormControl fullWidth>
        <InputLabel id="sort-select">Sort by</InputLabel>
        <Select
          labelId="sort-by"
          label="sort-by"
          id="sort-select"
          value={sortDataBy}
          onChange={(e) => setSortDataBy(e.target.value)}
          style={{ textTransform: 'capitalize' }}
        >
          <MenuItem value={'Date'}>Date</MenuItem>
          <MenuItem value={'Group'}>Group</MenuItem>
        </Select>
      </FormControl>
    </>
  );
}

export default GroupSelect;
