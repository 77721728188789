import EventOrGroupOverview from 'components/EventOrGroupOverview';
import LoadingIcon from 'components/LoadingIcon';
import useHttpGet from 'hooks/useHttpGet';

function AllGroups() {
  const { data: socialGroups, isLoading } = useHttpGet<any>(
    '/social-groups/groups-by-city/published'
  );

  return (
    <>
      {isLoading && !socialGroups ? (
        <LoadingIcon />
      ) : (
        <EventOrGroupOverview data={socialGroups} dataType={'Groups'} />
      )}
    </>
  );
}

export default AllGroups;
