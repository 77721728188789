import { ImageListItem } from '@mui/material';
import styled from '@mui/system/styled';

const { REACT_APP_API_URI } = process.env;

const itemData = [
  {
    img: `${REACT_APP_API_URI}/api/blobstorage/download-file/stock1.jpg`,
    title: 'Breakfast',
    author: '@bkristastucchio',
    featured: true,
  },
  {
    img: `${REACT_APP_API_URI}/api/blobstorage/download-file/stock2.jpg`,
    title: 'Burger',
    author: '@rollelflex_graphy726',
  },
  {
    img: `${REACT_APP_API_URI}/api/blobstorage/download-file/stock3.jpg`,
    title: 'Camera',
    author: '@helloimnik',
  },
  {
    img: `${REACT_APP_API_URI}/api/blobstorage/download-file/stock4.jpg`,
    title: 'Coffee',
    author: '@nolanissac',
  },
  {
    img: `${REACT_APP_API_URI}/api/blobstorage/download-file/stock5.jpg`,
    title: 'Hats',
    author: '@hjrc33',
  },
  {
    img: `${REACT_APP_API_URI}/api/blobstorage/download-file/stock6.jpg`,
    title: 'Honey',
    author: '@arwinneil',
    featured: true,
  },
];

const ImageGalleryList = styled('ul')(({ theme }) => ({
  display: 'grid',
  padding: 0,
  gap: 5,
  [theme.breakpoints.up('sm')]: {
    gridTemplateColumns: 'repeat(2, 1fr)',
    gridTemplateRows: '1fr',
  },
  [theme.breakpoints.up('md')]: {
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridAutoRows: '1fr',
  },
}));

function ChooseStock(props: { stockSelectedHandler: any }) {
  //Converts the url images to file
  const urlToObject = async (image: string, title: string) => {
    const response = await fetch(image);
    // here image is url/location of image
    const blob = await response.blob();
    const file = new File([blob], title);
    return file;
  };

  const { stockSelectedHandler } = props;

  return (
    <>
      <p className="uploadImageText">... or you can select a stock image</p>
      <ImageGalleryList>
        {itemData.map((item, index) => (
          <ImageListItem key={index}>
            <img
              src={item.img}
              className="stock-image"
              alt="random stock"
              onClick={async () =>
                stockSelectedHandler(await urlToObject(item.img, item.title))
              }
            />
          </ImageListItem>
        ))}
      </ImageGalleryList>
    </>
  );
}

export default ChooseStock;
