import { useAuth0 } from '@auth0/auth0-react';
import {
  Avatar,
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import UserAvatar from 'components/UserAvatar';
import useUser from 'hooks/useUser';
import { useState } from 'react';
import { useNavigate } from 'react-router';

export function UserLogin() {
  const { loginWithRedirect, logout } = useAuth0();
  const {
    isCoreMemberOrAdmin,
    fullName,
    imageUrl,
    isAuthenticated,
    isLoading,
  } = useUser();

  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorElUser);
  const navigate = useNavigate();

  return (
    <>
      {!isLoading && isAuthenticated && (
        <Box sx={{ flexGrow: 0 }}>
          <IconButton
            sx={{ p: 0 }}
            id="basic-button"
            onClick={(e) => setAnchorElUser(e.currentTarget)}
          >
            <UserAvatar fullName={fullName} imageUrl={imageUrl} size={40} />
          </IconButton>
          <Menu
            id="basic-menu"
            anchorEl={anchorElUser}
            open={open}
            onClose={() => setAnchorElUser(null)}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <MenuItem
              key={'Profile'}
              onClick={() => {
                setAnchorElUser(null);
                navigate(`/user`);
              }}
            >
              <Typography textAlign="center">Profile</Typography>
            </MenuItem>
            {isCoreMemberOrAdmin && (
              <MenuItem
                key={'manage'}
                onClick={() => {
                  navigate('/management');
                  setAnchorElUser(null);
                }}
              >
                <Typography textAlign="center">Manage</Typography>
              </MenuItem>
            )}
            <MenuItem
              key={'Logout'}
              onClick={() => {
                logout({ logoutParams: { returnTo: window.origin } });
                setAnchorElUser(null);
              }}
            >
              <Typography textAlign="center">Log out</Typography>
            </MenuItem>
          </Menu>
        </Box>
      )}
      {!isLoading && !isAuthenticated && (
        <Button
          disableElevation
          key={'login'}
          onClick={() => loginWithRedirect()}
          sx={{
            my: 2,
            display: 'block',
          }}
          color="inherit"
        >
          Log in
        </Button>
      )}
      {isLoading && (
        <IconButton
          onClick={(e) => setAnchorElUser(e.currentTarget)}
          sx={{ p: 0 }}
        >
          <Avatar alt="Remy Sharp" src={imageUrl} />
        </IconButton>
      )}
    </>
  );
}
