import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { Box, Button, Grid, Typography } from '@mui/material';
import { Interweave } from 'interweave';
import moment from 'moment';
import { useNavigate } from 'react-router';
import { SocialEventCalendar } from 'types';

function TextHelper(
  text: string,
  format: {
    className?: string;
    marginBottom?: number;
    marginLeft?: number;
    variant?: any;
  }
) {
  return <Typography {...format}>{text}</Typography>;
}

function IconAndDate(text: string, month: boolean) {
  return (
    <Grid container item direction="row" xs="auto">
      {month ? (
        <CalendarMonthIcon color="primary" />
      ) : (
        <AccessTimeIcon color="primary" />
      )}
      <Typography variant="body1">{text}</Typography>
    </Grid>
  );
}

function EventBox(props: {
  event: SocialEventCalendar;
  attendingEventIds: number[];
}) {
  const { title, groupName, description, eventId, startTime, endTime } =
    props.event;
  const { attendingEventIds } = props;
  const navigate = useNavigate();
  const momentized = {
    startTime: moment(startTime),
    endTime: moment(endTime),
  };

  // Show only events for the groups I am attending
  const isAttendingEvent = (eventId: number) => {
    return attendingEventIds.includes(eventId);
  };

  return (
    <>
      <Grid item xs={12}>
        <Grid
          container
          direction="column"
          sx={{
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
            borderRadius: '8px',
            marginBottom: '1em',
          }}
        >
          {isAttendingEvent(eventId) && (
            <Grid item xs={12}>
              <Box
                sx={{
                  width: '100%',
                  backgroundColor: 'primary.main',
                  borderRadius: '8px 8px 0px 0px',
                  paddingTop: '2px',
                  paddingBottom: '2px',
                }}
              >
                <Typography align="center">
                  You're attending this event
                </Typography>
              </Box>
            </Grid>
          )}
          <Grid item xs={12} padding={'10px'}>
            <Grid container item justifyContent="space-between" mb={1}>
              <Grid item xs={12} md order={{ xs: 2, md: 1 }}>
                <Typography variant="h4">{title}</Typography>
                <Typography variant="body1" color="primary">
                  {groupName}
                </Typography>
              </Grid>
              <Grid
                container
                item
                xs={12}
                md="auto"
                order={{ xs: 1, md: 2 }}
                justifyContent={{ xs: 'space-between' }}
                gap={2}
              >
                {IconAndDate(momentized.startTime.format('D'), true)}
                {IconAndDate(
                  `${momentized.startTime.format(
                    'HH:mm'
                  )} - ${momentized.endTime.format('HH:mm')}`,
                  false
                )}
              </Grid>
            </Grid>

            <Grid container item justifyContent="space-between" gap={2}>
              <Grid item xs={12} md={9}>
                <Typography
                  variant="body1"
                  sx={{ whiteSpace: 'pre-line', wordBreak: 'break-word' }}
                  color={'#838383'}
                >
                  <Interweave content={description} />
                </Typography>
              </Grid>
              <Grid container item xs={12} md="auto" alignItems="flex-end">
                <Button
                  disableElevation
                  color="primary"
                  sx={{ md: { width: '150px' } }}
                  fullWidth
                  onClick={() => navigate(`/events/${eventId}`)}
                  variant="outlined"
                >
                  View activity
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export { EventBox, TextHelper };
