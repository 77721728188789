import { isDev } from 'api/common';
import { useEffect, useRef, useState } from 'react';
import { pushRecievedPublisher } from 'helpers/pubsub';

import NotificationsIcon from '@mui/icons-material/NotificationsNoneRounded';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActiveOutlined';

import ListItemButton from '@mui/material/ListItemButton';
import Typography from '@mui/material/Typography';
import Popover from '@mui/material/Popover';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import CloseIcon from '@mui/icons-material/Close';
import { isDesktop } from 'react-device-detect';

type PushNotification = {
  id: string;
  title: string;
  body: string;
  url: string;
};

export default function PushNotifications() {
  const anchorEl = useRef<HTMLButtonElement>(null);
  const [shouldShow, setShouldShow] = useState(false);
  const [notifications, setNotifications] = useState<PushNotification[]>([]);
  const removeNotification = (id: string) =>
    setNotifications((prev) => prev.filter((n) => n.id !== id));

  useEffect(() => {
    pushRecievedPublisher.subscribe('notificationAlert', (e) => {
      if (!e.payload?.data) {
        if (isDev) {
          console.warn('No data sent from server in push notification', {
            payload: e.payload,
          });
        }
        return;
      }

      const title = e.payload.data?.title ?? 'Untitled';
      const body = e.payload.data?.body ?? 'No description';
      const url = e.payload.data?.url ?? '/';
      if (!isDesktop) {
        const n = new window.Notification(title, {
          body,
        });

        n.onclick = () => {
          window.open(url, window.name);
        };
      }

      if (isDesktop) {
        setNotifications((prev) => [
          {
            id: crypto.randomUUID(),
            url,
            body,
            title,
          },
          ...prev,
        ]);
      }
    });

    return () => pushRecievedPublisher.unsubscribe('notificationAlert');
  }, []);

  return (
    <div>
      <IconButton
        ref={anchorEl}
        aria-describedby="push-notifications-list"
        onClick={() => setShouldShow((prev) => !prev)}
        size="large"
      >
        {notifications.length <= 0 && <NotificationsIcon />}
        {notifications.length > 0 && (
          <>
            <Typography color="error" position="absolute" top={0} right={5}>
              {notifications.length}
            </Typography>
            <NotificationsActiveIcon color="error" />
          </>
        )}
      </IconButton>
      {notifications.length > 0 && (
        <Popover
          id="push-notifications-list"
          anchorEl={anchorEl.current}
          open={shouldShow}
          onClose={() => setShouldShow(false)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <List>
            {notifications.map((n) => (
              <ListItemButton key={n.id} href={n.url}>
                <ListItemText
                  sx={{
                    maxWidth: 200,
                    width: 200,
                    overflowWrap: 'break-word',
                    overflow: 'clip',
                  }}
                  primary={n.title}
                  secondary={n.body}
                />
                <IconButton
                  edge="end"
                  onClick={(e) => {
                    e.preventDefault();
                    removeNotification(n.id);
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </ListItemButton>
            ))}
          </List>
        </Popover>
      )}
    </div>
  );
}
