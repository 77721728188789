import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import EventOrGroupList from 'components/EventOrGroupList';
import LoadingIcon from 'components/LoadingIcon';
import useHttpGet from 'hooks/useHttpGet';
import useUser from 'hooks/useUser';
import { useState } from 'react';
import { SocialEventOverview } from 'types';

export default function ManageEventsPage() {
  const { location, authorizedEvents } = useUser();
  const { data: events, isLoading: isLoadingEvents } = useHttpGet<
    SocialEventOverview[]
  >(`/locations/${location}/upcoming-events`);

  const [showPublished, setShowPublished] = useState(true);
  const [eventSearch, setEventSearch] = useState('');
  const [selectedOrder, setSelectionOrder] = useState<string | number>(0);

  const filterEvents = (events: any) => {
    // Published / Unpublished filter
    const publishFilter = events.filter(
      (event: SocialEventOverview) => event.isPublished === showPublished
    );

    // Search filter
    const searchFilter = publishFilter.filter(
      (event: SocialEventOverview) =>
        event.title.toLowerCase().includes(eventSearch.toLowerCase()) ||
        event.groupName.toLowerCase().includes(eventSearch.toLowerCase())
    );

    // Authorized events filter
    let authorizedFilter = searchFilter;
    if (authorizedEvents) {
      authorizedFilter = authorizedFilter.filter((event: any) =>
        authorizedEvents.includes(event.eventId)
      );
    }

    // Date filter
    let dateFilter = authorizedFilter;
    if (selectedOrder === 1) {
      dateFilter = authorizedFilter.reverse();
    }

    return dateFilter;
  };

  if (isLoadingEvents) {
    return <LoadingIcon />;
  }

  return (
    <>
      <Grid container direction={'column'} gap={2}>
        <Typography variant="h1" marginTop={'1em'}>
          {showPublished ? 'Published Activities' : 'Hidden Activities'}
        </Typography>

        <Grid container direction="row" gap={2}>
          <Button
            disableElevation
            variant={showPublished ? 'contained' : 'text'}
            onClick={() => setShowPublished(true)}
            color="primary"
          >
            <Typography color="inherit">Published</Typography>
          </Button>
          <Button
            disableElevation
            variant={!showPublished ? 'contained' : 'text'}
            onClick={() => setShowPublished(false)}
            color="primary"
          >
            <Typography color="inherit">Hidden</Typography>
          </Button>
        </Grid>

        <Grid container gap={2} my={2}>
          <Grid item xs={12} sm={8} md={3}>
            <TextField
              fullWidth
              label="Search"
              minRows={1}
              value={eventSearch}
              onChange={(e) => setEventSearch(e.target.value)}
            />
          </Grid>

          <Grid item xs={12} sm={8} md={3}>
            <FormControl fullWidth>
              <InputLabel id="orderLabel">Order</InputLabel>
              <Select
                labelId="orderLabel"
                label="Order"
                value={selectedOrder}
                onChange={(e) => setSelectionOrder(e.target.value)}
              >
                <MenuItem value={0}>Newest activities</MenuItem>
                <MenuItem value={1}>Oldest activities</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>

      {events && <EventOrGroupList data={filterEvents(events) || []} />}
    </>
  );
}
