import CreateEventForm from 'components/CreateEventForm';
import EditEventForm from 'components/EditEventForm';
import { useParams } from 'react-router';

export default function CreateOrEditEventPage() {
  const { eventId } = useParams();

  return (
    <>{eventId ? <EditEventForm eventId={eventId} /> : <CreateEventForm />}</>
  );
}
