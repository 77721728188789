import axiosInstance from 'api/axiosInstance';
import { AxiosRequestConfig } from 'axios';
import { useState } from 'react';

function useHttpDelete<TPayload = any>(config?: AxiosRequestConfig) {
  const [error, setError] = useState<Error>();
  const [isLoading, setIsLoading] = useState<boolean>();

  const httpDelete = async (url: string, payload?: TPayload) => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.delete(url, {
        ...config,
        data: payload,
      });
      setError(undefined);
      return response;
    } catch (err) {
      setError(err as Error);
      throw err;
    } finally {
      setIsLoading(false);
    }
  };

  return { error, isLoading, httpDelete };
}

export default useHttpDelete;
