import {
  Checkbox,
  FormControlLabel,
  Grid,
  Slider,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { SocialEventForm } from 'types';

function CapacityInfoForm(props: {
  eventFormState: SocialEventForm;
  setEventFormState: any;
  hasErrorState: any;
  setHasErrorState: any;
}) {
  const { eventFormState, setEventFormState } = props;

  const [hasMaxCapicity, setHasMaxCapicity] = useState<boolean>(false);
  const defaultMaxCapacity = eventFormState.maxCapacity ?? 45;
  const handleCapacityChange = () => {
    setEventFormState((prev: SocialEventForm) => ({
      ...prev,
      maxCapacity: hasMaxCapicity ? null : defaultMaxCapacity,
    }));

    setHasMaxCapicity((prev) => !prev);
  };
  useEffect(() => {
    if (eventFormState.maxCapacity) {
      setHasMaxCapicity(true);
    }
  }, [eventFormState.maxCapacity]);

  return (
    <Grid container item gap={2}>
      <Typography variant="h3">Capacity</Typography>
      <Grid container alignItems="center">
        <FormControlLabel
          control={
            <Checkbox
              color="secondary"
              checked={hasMaxCapicity}
              onChange={handleCapacityChange}
            />
          }
          label="Is there a maximum capacity?"
        />

        <Typography fontWeight="bold">
          Maximum Capacity: {eventFormState.maxCapacity ?? 'open for all'}
        </Typography>
        {hasMaxCapicity && (
          <Slider
            min={1}
            max={200}
            defaultValue={defaultMaxCapacity}
            onChangeCommitted={(_, number) => {
              setEventFormState((prev: SocialEventForm) => ({
                ...prev,
                maxCapacity: number,
              }));
            }}
            step={1}
            valueLabelDisplay="auto"
            color="secondary"
          />
        )}
      </Grid>
    </Grid>
  );
}

export default CapacityInfoForm;
