import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Button, FormControl, Menu, MenuItem, Typography } from '@mui/material';
import useHttpGet from 'hooks/useHttpGet';
import { MouseEvent, useState } from 'react';
import { useNavigate } from 'react-router';
import { LocationBase } from 'types';
import './index.scss';

export function LocationMenu(props: { isCurrentPath: boolean }) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { isCurrentPath } = props;
  const { data: cities } = useHttpGet<LocationBase[]>('/locations');
  const navigate = useNavigate();
  return (
    <>
      <Button
        disableElevation
        className={
          isCurrentPath ? 'nav-button nav-button-active' : 'nav-button'
        }
        aria-haspopup="true"
        onClick={handleClick}
        style={{ backgroundColor: 'transparent' }}
        sx={{
          color: 'primary.main',
          borderRadius: 0,
          marginX: 2,
          paddingLeft: 2,
          transition: 'none',
          WebkitTransition: 'none',
        }}
      >
        <Typography variant="h5" color={'black'}>
          Locations
        </Typography>
        <ArrowDropDownIcon />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        onClose={handleClose}
        color="inherit"
      >
        <FormControl variant="filled" sx={{ m: 1, minWidth: 120 }}>
          {cities &&
            cities.map((city: LocationBase) => (
              <MenuItem
                key={city.city}
                onClick={() => {
                  navigate(`/locations/${city.city.toLowerCase()}`);
                  handleClose();
                }}
              >
                {city.city}
              </MenuItem>
            ))}
        </FormControl>
      </Menu>
    </>
  );
}
