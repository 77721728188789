import { ImageList, ImageListItem } from '@mui/material';

function srcset(image: string, size: number, rows = 1, cols = 1) {
  return {
    src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${size * cols}&h=${
      size * rows
    }&fit=crop&auto=format&dpr=2 2x`,
  };
}

const IntroductionImagesComponent = (props: { location: any }) => {
  const { REACT_APP_API_URI } = process.env;
  const { location } = props;

  const image1 = `${REACT_APP_API_URI}/api/blobstorage/download-City-Images/${
    location.city ?? 'Home'
  }1.jpg`;
  const image2 = `${REACT_APP_API_URI}/api/blobstorage/download-City-Images/${
    location.city ?? 'Home'
  }2.jpg`;
  const image3 = `${REACT_APP_API_URI}/api/blobstorage/download-City-Images/${
    location.city ?? 'Home'
  }3.jpg`;
  const image4 = `${REACT_APP_API_URI}/api/blobstorage/download-City-Images/${
    location.city ?? 'Home'
  }4.jpg`;

  const getImageData = () => {
    // Get data from api, create img data type
    const data: any = [
      {
        img: image1,
        title: '500',
        rows: 2,
        cols: 2,
      },
      {
        img: image2,
        title: '600',
      },
      {
        img: image3,
        title: '700',
      },
      {
        img: image4,
        title: '800',
        cols: 2,
      },
    ];

    return data;
  };

  return (
    <>
      <ImageList
        sx={{ width: 'auto', height: 'auto' }}
        variant="quilted"
        cols={4}
        rowHeight={200}
      >
        {getImageData().map((item: any) => (
          <ImageListItem
            key={item.img}
            cols={item.cols || 1}
            rows={item.rows || 1}
          >
            <img
              {...srcset(item.img, 120, item.rows, item.cols)}
              alt={item.title}
              style={{ borderRadius: 10 }}
            />
          </ImageListItem>
        ))}
      </ImageList>
    </>
  );
};

export default IntroductionImagesComponent;
