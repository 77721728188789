import { Box, Button, CardMedia, Grid, Typography } from '@mui/material';
import GetImageUrl from 'helpers/GetImage';
import useHttpGet from 'hooks/useHttpGet';
import moment from 'moment';
import { isMobile } from 'react-device-detect';
import { useNavigate, useParams } from 'react-router';
import './index.scss';

function EventImage(props: {
  imageUrl: string;
  title: string;
  groupName: string;
  hostingGroups: any;
  deadlineAttendance: string;
  startAttendance: string;
}) {
  const { id: eventId } = useParams();
  const {
    imageUrl,
    title,
    groupName,
    hostingGroups,
    deadlineAttendance,
    startAttendance,
  } = props;
  const { data: eventCreator } = useHttpGet<string>(
    `events/${eventId}/EventCreator`
  );
  const navigate = useNavigate();

  const BannerCheck = () => {
    const today = new Date();
    const start = new Date(startAttendance);
    if (startAttendance != null && today < start) {
      return true;
    }
  };

  return (
    <Grid item className="eventimage-image-container">
      {deadlineAttendance && !BannerCheck() && (
        <Box
          component="div"
          className="deadline-banner"
          sx={{ backgroundColor: 'primary.main' }}
        >
          <Typography variant="h4" sx={{ color: 'info.main' }}>
            Attendance deadline:{' '}
            {moment(deadlineAttendance).format('MMM D HH:mm')}
          </Typography>
        </Box>
      )}
      {BannerCheck() && (
        <Box
          component="div"
          className="deadline-banner"
          sx={{ backgroundColor: 'primary.main' }}
        >
          <Typography variant="h4" sx={{ color: 'info.main' }}>
            Attendance opens: {moment(startAttendance).format('MMM D HH:mm')}
          </Typography>
        </Box>
      )}
      <CardMedia
        className="eventimage-image"
        sx={{ objectFit: 'cover' }}
        component="img"
        image={GetImageUrl(imageUrl)}
      />
      <Grid container className="eventimage-fader">
        <Grid sx={{ position: 'absolute', bottom: '1.5rem' }}>
          <Typography variant={isMobile ? 'h4' : 'h3'} color="white">
            <Button
              disableElevation
              onClick={() =>
                navigate(`/groups/${hostingGroups[0].socialGroupId}`)
              }
              sx={{
                textDecoration: 'none',
              }}
            >
              <Typography
                variant={isMobile ? 'h5' : 'h4'}
                sx={{
                  color: 'white',
                }}
              >
                {groupName}
              </Typography>
            </Button>
          </Typography>
          <Typography
            variant={isMobile ? 'h5' : 'h4'}
            sx={{
              color: 'white',
            }}
          >
            {eventCreator}
          </Typography>
          <Typography
            sx={{
              fontWeight: isMobile ? '350' : '500',
              fontSize: isMobile ? '35px' : '50px',
              color: 'white',
            }}
          >
            {title}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default EventImage;
