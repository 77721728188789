import { Divider, Grid, Typography } from '@mui/material';
import moment from 'moment';
import { Fragment } from 'react';
import { isMobile } from 'react-device-detect';
import { SocialEventCalendar } from 'types';
import { EventBox } from './EventBox';

function datesOfWeek(week: string, year: string) {
  const startOfWeek = moment()
    .year(JSON.parse(year))
    .week(JSON.parse(week))
    .weekday(1)
    .format('MMM Do');

  const endOfWeek = moment()
    .year(JSON.parse(year))
    .week(JSON.parse(week))
    .weekday(7)
    .format('MMM Do');

  return `${startOfWeek} - ${endOfWeek}`;
}

function EventListByWeek(props: {
  entry: [string, Map<string, SocialEventCalendar[]>];
  attendingEventIds: number[];
}) {
  const { entry, attendingEventIds } = props;
  const year = entry[0];
  const week = entry[1];

  return (
    <>
      {Object.entries(week).map((weekEntry: any, weekIndex: number) => (
        <Fragment key={weekIndex}>
          <Grid
            item
            container
            xs={12}
            direction="column"
            alignItems={isMobile ? 'center' : ''}
            mt={3}
            mb={2}
          >
            <Grid
              container
              justifyContent="space-between"
              mb={isMobile ? 2 : 1}
            >
              <Grid item xs={12} md="auto">
                <Typography
                  variant="h3"
                  align="center"
                >{`Week ${weekEntry[0]}`}</Typography>
              </Grid>
              <Grid item xs={12} md="auto">
                <Typography variant="body1" align="center" color={'#838383'}>
                  {datesOfWeek(weekEntry[0], year)}
                </Typography>
              </Grid>
            </Grid>

            {weekEntry[1]?.map(
              (ev: SocialEventCalendar, eventIndex: number) => (
                <EventBox
                  event={ev}
                  key={eventIndex}
                  attendingEventIds={attendingEventIds ?? []}
                />
              )
            )}
          </Grid>
          <Divider />
        </Fragment>
      ))}
    </>
  );
}

export default EventListByWeek;
