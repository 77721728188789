import { Button, Typography } from '@mui/material';
import { Box } from '@mui/system';
import EventOrGroupList from 'components/EventOrGroupList';
import moment from 'moment';
import { useState } from 'react';
import { SocialEventCalendar } from 'types';
import CalendarGrid from './CalendarGrid';
import Navigation from './Navigation';
import './index.scss';

export default function CalendarView(props: {
  selectedDate: Date;
  setSelectedDate: any;
  events: SocialEventCalendar[];
}) {
  const { selectedDate, setSelectedDate, events } = props;
  const [dayViewDate, setDayViewDate] = useState<any | undefined>(undefined);

  const filterSelectedEvents = (
    allEvents: SocialEventCalendar[],
    selectedEvents: SocialEventCalendar[]
  ) => {
    const filteredEvents = allEvents.filter((event: SocialEventCalendar) => {
      return selectedEvents.find(
        (cEvent: SocialEventCalendar) => event.eventId === cEvent.eventId
      );
    });
    return filteredEvents;
  };

  return (
    <>
      {dayViewDate ? (
        <Box>
          <Button
            variant="outlined"
            onClick={() => setDayViewDate(undefined)}
            sx={{ marginBottom: '1em' }}
          >
            Back to calendar
          </Button>
          <Typography variant="h4">
            {moment(dayViewDate.date).format('MMMM Do YYYY')}
          </Typography>
          <EventOrGroupList
            data={filterSelectedEvents(events, dayViewDate.events)}
          />
        </Box>
      ) : (
        <>
          <Navigation date={selectedDate} setDate={setSelectedDate} />
          <CalendarGrid
            selectedDate={selectedDate}
            events={events}
            setDayViewDate={setDayViewDate}
          />
        </>
      )}
    </>
  );
}
