import { Box, Button, Divider } from '@mui/material';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import EventOrGroupPopup from 'components/EventOrGroupPopup';
import ImageDrop from 'components/FileHandler';
import { isAllValid } from 'helpers/inputValidator';
import useLoadUser from 'hooks/loadUser';
import useHttpGet from 'hooks/useHttpGet';
import useHttpWithData from 'hooks/useHttpWithData';
import useUser from 'hooks/useUser';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { EditSocialGroup, LocationBase, UserWithImage } from 'types';
import GetImageUrl from '../../helpers/GetImage';
import GroupAdminInfoForm from './GroupAdminInfoForm';
import GroupCoreMembersInfoForm from './GroupCoreMembersInfoForm';
import GroupInfoForm from './GroupInfoForm';
import UploadCarouselPic from 'components/ImageStepper/UploadModal';
import DeleteImageModal from 'components/ImageStepper/DeleteImageCarousel';

function CreateGroupForm(props: {
  groupId?: string;
  data?: any;
  carouselData?: any;
}) {
  const { groupId, data, carouselData } = props;
  const { reloadUser } = useLoadUser();
  const navigate = useNavigate();
  const [previewUrl, setPreviewUrl] = useState(data?.imageUrl ?? '');
  const [groupFormState, setGroupFormState] = useState<EditSocialGroup>({
    name: data ? data.name : '',
    description: data ? data.description : '',
    file: undefined,
    groupLeadMember: data ? (data.groupLeadMember as UserWithImage) : null,
    groupCoreMembers: data
      ? ([data.groupLeadMember].concat(
          data.groupCoreMembers
        ) as UserWithImage[])
      : [],
  });

  const [hasErrorState, setHasErrorState] = useState({
    name: data ? !data.name : false,
    description: data ? !data.description : false,
    file: data ? !data.imageUrl : false,
    groupLeadMember: data ? !data.groupLeadMember : true,
    groupCoreMembers: data ? !data.groupCoreMembers : false,
  });

  const [isPreviewDialogOpen, setPreviewDialogOpen] = useState(false);

  const { location: userLocation } = useUser();

  const {
    send: createGroup,
    isLoading: postingNewGroup,
    data: createData,
    status: createStatus,
  } = useHttpWithData<any>({
    url: `/social-groups`,
    method: 'post',
    config: { headers: { 'Content-Type': 'multipart/form-data' } },
  });
  const {
    send: editGroup,
    isLoading: postingEditGroup,
    data: editData,
    status: editStatus,
  } = useHttpWithData<any>({
    url: `/social-groups`,
    method: 'put',
    config: { headers: { 'Content-Type': 'multipart/form-data' } },
  });

  const { data: locations } = useHttpGet<LocationBase[]>('/locations');

  function hasNewImage() {
    return groupFormState.file && groupFormState.file.size > 0;
  }

  useEffect(() => {
    if (hasNewImage()) {
      setPreviewUrl(URL.createObjectURL(groupFormState.file ?? new Blob()));
    }
  }, [groupFormState.file]);

  const handleFormSubmit = async () => {
    const locationId =
      locations
        ?.find((l) => l.city.toLowerCase() === userLocation)
        ?.locationId.toString() ?? '';
    if (groupId) {
      const form = new FormData();
      form.append('socialGroupId', data.socialGroupId.toString()); // Okay to get from data, since not part of editing
      form.append('name', groupFormState.name);
      form.append('description', groupFormState.description);
      form.append('locationId', locationId);
      form.append('file', groupFormState.file ?? '');
      form.append('imageUrl', data?.imageUrl);
      form.append('isPublished', data.isPublished); // Okay to get from data, since not part of editing
      form.append('isGeneral', data.isGeneral); // Okay to get from data, since not part of editing
      form.append(
        'groupLeadMemberId',
        groupFormState?.groupLeadMember?.appUserId ?? '' // Never happens, but would not let me do ?.appUserId
      );
      form.append(
        'groupCoreMembersId',
        JSON.stringify(groupFormState.groupCoreMembers?.map((m) => m.appUserId))
      );

      await editGroup(form);
    } else {
      const form = new FormData();
      form.append('name', groupFormState.name);
      form.append('description', groupFormState.description);
      form.append('locationId', locationId);
      form.append('file', groupFormState.file ?? '');
      form.append(
        'groupLeadMemberId',
        groupFormState.groupLeadMember
          ? groupFormState.groupLeadMember.appUserId
          : '' // Never happens, but would not let me do ?.appUserId
      );
      form.append(
        'groupCoreMembersId',
        JSON.stringify(groupFormState.groupCoreMembers?.map((m) => m.appUserId))
      );

      await createGroup(form);
    }
  };

  useEffect(() => {
    if (createStatus === 200 || editStatus === 200) {
      setPreviewDialogOpen(false);
      reloadUser();
      navigate(`/groups/${createData ?? editData}`);
    }
  }, [createData, editData]);

  return (
    <>
      <Grid container gap={8} direction={'column'} p={2}>
        <Box>
          <Typography variant="h1">
            {groupId ? 'Edit' : 'Create'} Group
          </Typography>
          <Typography variant="subtitle1" mb={4} sx={{ color: '#838383' }}>
            Sections marked with{' '}
            <Box component="span" color="red">
              *
            </Box>{' '}
            are required
          </Typography>
        </Box>
        <GroupInfoForm
          groupFormState={groupFormState}
          setGroupFormState={setGroupFormState}
          hasErrorState={hasErrorState}
          setHasErrorState={setHasErrorState}
        />
        <Divider />
        <ImageDrop
          setFormState={setGroupFormState}
          errorState={hasErrorState}
          setErrorState={setHasErrorState}
          prevImage={data ? data.imageUrl : ''}
        />
        <Divider />
        <div
          style={{
            boxShadow: '0 1rem 1.1rem 0.18rem rgba(0,0,0,0.1)',
            padding: '16px',
            borderRadius: '1rem',
          }}
        >
          {groupId && (
            <DeleteImageModal groupId={groupId} carouselData={carouselData} />
          )}
          <Divider />
          {groupId && <UploadCarouselPic id={groupId} />}
        </div>
        <Divider />
        <GroupCoreMembersInfoForm
          groupFormState={groupFormState}
          setGroupFormState={setGroupFormState}
          setHasErrorState={setHasErrorState}
        />
        <Divider />
        <GroupAdminInfoForm
          groupFormState={groupFormState}
          setGroupFormState={setGroupFormState}
          setHasErrorState={setHasErrorState}
        />
        <Grid container item justifyContent="flex-end">
          <Button
            disableElevation
            variant="contained"
            className="create-event-button"
            disabled={
              !isAllValid(groupFormState, hasErrorState, 'groupCoreMembers')
            }
            onClick={() => setPreviewDialogOpen(true)}
          >
            <Box> {!groupId ? 'Create' : 'Edit'} & Preview </Box>
          </Button>
        </Grid>
        {isPreviewDialogOpen && (
          <EventOrGroupPopup
            open={isPreviewDialogOpen}
            setOpen={setPreviewDialogOpen}
            handleFormSubmit={handleFormSubmit}
            formData={groupFormState}
            previewUrl={hasNewImage() ? previewUrl : GetImageUrl(data.imageUrl)}
            waitPostRequest={groupId ? postingEditGroup : postingNewGroup}
            isEvent={false}
          />
        )}
      </Grid>
    </>
  );
}

export default CreateGroupForm;
