import ArrowForwardIcon from '@mui/icons-material/ArrowForwardIos';
import { Box, Button, Grid, Typography } from '@mui/material';
import useHttpGet from 'hooks/useHttpGet';
import { isMobile } from 'react-device-detect';
import { useNavigate, useParams } from 'react-router';
import { SocialGroup } from 'types';
import GroupOverviewCard from '../../components/GroupOverviewCard';

const RightCard = (group: SocialGroup) => (
  <Grid item key={group.socialGroupId} xs maxHeight="280px">
    <GroupOverviewCard {...group} />
  </Grid>
);

function GroupCardAlignment() {
  const { city } = useParams();
  const { data: groups } = useHttpGet<SocialGroup[]>(
    `/locations/${city}/random-groups`
  );
  const navigate = useNavigate();

  if (!groups?.length) return <></>;
  return (
    <>
      <Grid
        container
        direction="column"
        id="explore-group-overview"
        style={{
          alignItems: isMobile ? 'center' : '',
        }}
      >
        <Typography variant="h3" marginBottom="0.5em">
          Groups in{' '}
          <Box
            sx={{
              textTransform: 'capitalize',
              backgroundColor: 'primary.light',
              display: 'inline-block',
            }}
          >
            {city}
          </Box>
        </Typography>
        {isMobile ? (
          <Grid container direction="column" gap={2} marginBottom={'2em'}>
            {groups.map((group) => {
              return (
                <Grid item key={group.socialGroupId} xs={12}>
                  <GroupOverviewCard {...group} />
                </Grid>
              );
            })}
          </Grid>
        ) : (
          <Grid container gap={2} marginBottom={'2em'}>
            <Grid item xs={12} md maxHeight={'600px'}>
              <GroupOverviewCard {...groups[0]} />
            </Grid>
            <Grid item xs={12} sm>
              <Grid container direction="column" gap={2}>
                {groups.slice(1).map((group) => RightCard(group))}
              </Grid>
            </Grid>
          </Grid>
        )}
        <Button
          disableElevation
          endIcon={<ArrowForwardIcon />}
          style={{ width: 'fit-content' }}
          sx={{ color: 'primary.main', margin: 'auto' }}
          onClick={() => navigate(`/groups?city=${city}`)}
        >
          <Typography fontWeight={'bold'}>Explore groups</Typography>
        </Button>
      </Grid>
    </>
  );
}

export default GroupCardAlignment;
