import React, { useEffect } from 'react';
import Button from '@mui/material/Button';
import './OverlayModal.scss';

interface ModalProps {
  children: React.ReactNode;
  onClose: (event: React.MouseEvent) => void;
}

const Modal: React.FC<ModalProps> = ({ children, onClose }) => {
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = '';
    };
  }, []);

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <Button
          className="close-button"
          disableElevation
          variant="contained"
          color="secondary"
          sx={{ color: 'white', float: 'right', position: 'absolute' }}
          onClick={onClose}
        >
          Close
        </Button>
        {children}
      </div>
    </div>
  );
};

export default Modal;
