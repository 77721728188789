import { PersonAdd } from '@mui/icons-material';
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import ConfirmationBox from 'components/ConfirmationBox/ConfirmationBox';
import useHttpGet from 'hooks/useHttpGet';
import useHttpWithData from 'hooks/useHttpWithData';
import { useState } from 'react';
import { AttendingUser, UserBase } from 'types';

interface AddAttendeesDropdownProps {
  allAttendees: AttendingUser[];
  eventId: string;
  onAddAttendees: () => void;
}
interface EventAttendance {
  appUserId: string;
  eventId: string;
}
const AddAttendeesDropdown = ({
  allAttendees,
  eventId,
  onAddAttendees,
}: AddAttendeesDropdownProps) => {
  const [selectedUser, setSelectedUser] = useState<string[]>([]);

  const handleChange = (event: SelectChangeEvent<string[]>) => {
    setSelectedUser(event.target.value as string[]);
  };
  const { send: registerJoinAttendance, isLoading: isLoadingJoin } =
    useHttpWithData<EventAttendance>({
      url: '/event-attendances/join',
      method: 'put',
    });

  const [openConfirmationBox, setOpenConfirmationBox] = useState(false);

  const { data: users } = useHttpGet<UserBase[]>('/users');

  const handleAddAttendees = () => {
    if (selectedUser.length === 0) {
      console.log('No user selected to add.');
      return;
    }
    const attendeesToAdd = selectedUser.map((appUserId) => ({
      appUserId: appUserId.toString(),
      eventId: eventId,
    }));
    attendeesToAdd.map((attendee) => {
      return registerJoinAttendance(attendee);
    });
    setSelectedUser([]);
    onAddAttendees();
  };
  const filteredUsers = users?.filter(
    (user) =>
      !allAttendees.some((attendee) => attendee.appUserId === user.appUserId)
  );

  return (
    <>
      <Grid
        style={{
          marginTop: '10px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography>Add Attendees:</Typography>
        <FormControl sx={{ width: 300 }} variant="standard">
          <InputLabel id="add-attendees-label">Select Users</InputLabel>
          <Select
            labelId="add-attendees-label"
            id="add-attendees"
            multiple
            onChange={handleChange}
            value={selectedUser}
          >
            {filteredUsers
              ?.sort((a, b) => a.fullName.localeCompare(b.fullName))
              .map((user) => (
                <MenuItem key={user.appUserId} value={user.appUserId}>
                  {user.fullName}
                </MenuItem>
              ))}
          </Select>
        </FormControl>

        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setOpenConfirmationBox(true)}
            disabled={isLoadingJoin}
            disableElevation
            sx={{ height: '3rem', marginLeft: '0.313rem', marginTop: '0.5rem' }}
          >
            Add Selected Users <PersonAdd sx={{ marginLeft: '0.5rem' }} />
          </Button>
        </Grid>
      </Grid>
      {isLoadingJoin ? 'Loading' : ''}
      <ConfirmationBox
        open={openConfirmationBox}
        title={`Add Selected Users?`}
        content={''}
        handleClose={() => {
          setOpenConfirmationBox(false);
        }}
        handleResponse={(response) => {
          if (response) handleAddAttendees();
        }}
      />
    </>
  );
};
export default AddAttendeesDropdown;
