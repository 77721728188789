import CloseIcon from '@mui/icons-material/Close';
import Drawer from '@mui/material/Drawer';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import { isMobile } from 'react-device-detect';
import SideNavContent from './SideNavContent';

export type MuiVariant = 'temporary' | 'permanent';
interface Props {
  variant: MuiVariant;
  isOpen: boolean;
  onClose?: () => void;
}

const CloseButton = (onClose?: () => void) => (
  <Grid container justifyContent={'flex-end'} p={1}>
    <IconButton onClick={onClose}>
      <CloseIcon htmlColor="white" />
    </IconButton>
  </Grid>
);

function SideNavDrawer({ isOpen, onClose, variant }: Props) {
  return (
    <>
      <Grid container item>
        <Drawer
          PaperProps={{
            sx: {
              width: isMobile ? '100%' : '300px',
              backgroundColor: 'secondary.main',
            },
          }}
          variant={variant}
          anchor={'left'}
          open={isOpen}
          onClose={onClose}
        >
          {!isMobile && <Toolbar />}
          <Grid container role="presentation">
            {isMobile && CloseButton(onClose)}
            <SideNavContent onClose={onClose} />
          </Grid>
        </Drawer>
      </Grid>
    </>
  );
}

export default SideNavDrawer;
