import CreateGroupForm from 'components/CreateGroupForm';
import LoadingIcon from 'components/LoadingIcon';
import useHttpGet from 'hooks/useHttpGet';

function EditGroupForm(props: { groupId: string }) {
  // Fetch data
  const { groupId } = props;
  const { data } = useHttpGet<any>(`/social-groups/${groupId}/edit`);
  const { data: carouselData } = useHttpGet<any>(
    `/blobstorage/socialGroup-Carousel-byte/${groupId}`
  );

  return (
    <>
      {data ? (
        <CreateGroupForm
          groupId={groupId}
          data={data}
          carouselData={carouselData}
        />
      ) : (
        <LoadingIcon />
      )}
    </>
  );
}

export default EditGroupForm;
