import CloseIcon from '@mui/icons-material/Close';
import { Dialog, Grid } from '@mui/material';
import { Box } from '@mui/system';
import EventOrGroupCard from 'components/EventOrGroupCard';
import { useState } from 'react';
import { SocialEventCalendar } from 'types';
import './index.scss';

export default function MiniEvent(props: {
  events: SocialEventCalendar[];
  selectedEvent: number;
  setSelectedEvent: any;
}) {
  const { events, selectedEvent, setSelectedEvent } = props;
  const [showMoreDialog, setShowMoreDialog] = useState(false);

  const filterOnSelectedEvents = (
    allEvents: SocialEventCalendar[],
    eventId: number
  ) => {
    if (eventId !== -1) {
      const singleEvent = allEvents.filter((event: SocialEventCalendar) => {
        return event.eventId === eventId;
      });
      return singleEvent;
    }

    return allEvents;
  };

  return (
    <>
      {events.map((event: SocialEventCalendar, index: number) => (
        <Box
          key={index}
          className={`miniEvent`}
          sx={{
            backgroundColor: 'primary.main',
            ':hover': {
              backgroundColor: 'primary.dark',
            },
          }}
          onClick={() => {
            setSelectedEvent(event.eventId);
            setShowMoreDialog(true);
          }}
        >
          {event.groupName} <br />
          {event.title}
        </Box>
      ))}

      {showMoreDialog && (
        <Dialog open={showMoreDialog} className="dialogBox">
          <Box sx={{ padding: '30px' }}>
            <CloseIcon
              className="cross-corner"
              onClick={() => setShowMoreDialog(false)}
            />
            <Grid container rowSpacing={4}>
              {filterOnSelectedEvents(events, selectedEvent).map(
                (o: any, idx: number) => (
                  <Grid item xs={12} key={idx}>
                    <EventOrGroupCard data={o} />
                  </Grid>
                )
              )}
            </Grid>
          </Box>
        </Dialog>
      )}
    </>
  );
}
