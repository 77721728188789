import ImageCarousel from 'components/ImageCarousel';

function BigScreen() {
  return (
    <>
      <ImageCarousel />
    </>
  );
}

export default BigScreen;
