import { Divider, SelectChangeEvent, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import EventOrGroupList from 'components/EventOrGroupList';
import EventsOrGroupsPreview from 'components/EventsOrGroupsPreview';
import moment from 'moment';
import FilterRow from 'pages/AllGroups/FilterRow';
import NavButtons from 'pages/AllGroups/NavButtons';
import { Fragment, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

function searchFilter(data: any, eventValue: string, city: string) {
  return data?.filter(
    (o: any) =>
      o.title?.toLowerCase().includes(eventValue.toLowerCase()) ||
      o.description?.toLowerCase().includes(eventValue.toLowerCase()) ||
      o.groupName?.toLowerCase().includes(eventValue.toLowerCase()) ||
      o.name?.toLowerCase().includes(eventValue.toLowerCase())
  );
}

function EventOrGroupOverview(props: {
  data: any;
  dataType?: 'Activities' | 'Groups';
}) {
  const { data, dataType } = props;

  const [filteredData, setFilteredData] = useState<any>();
  const [cityFiltered, setCityFiltered] = useState<any>();
  const [searchParams] = useSearchParams();
  const [sortDataBy, setSortDataBy] = useState('Date');

  useEffect(() => {
    const city = searchParams.get('city')?.toLowerCase() || 'all';
    let result: any;
    let cities = Object.keys(data ?? []);
    if (!cities.includes(city)) {
      result = Object.values(data || {}).reduce(
        (prev: any, current: any) => [...prev, ...current],
        []
      );
    } else {
      result = (data && data[city ?? 'all']) ?? [];
    }

    result = result.sort(function (a: any, b: any) {
      return moment(a.startTime).diff(moment(b.startTime));
    });

    setCityFiltered(result ?? []);
    setFilteredData(result ?? []);
  }, [searchParams, data]);

  const handleTextFilter = (e: SelectChangeEvent<string>) => {
    const city = searchParams.get('city')?.toLowerCase() || 'all';
    const eventValue = e.target.value;
    const result = searchFilter(cityFiltered!, eventValue, city!);
    setFilteredData(result?.length ? result : []);
  };

  const handleGroupSearch = (data: any) => {
    const groupedEvents = data.reduce((groups: any, event: any) => {
      const group = groups[event.groupName] || [];
      group.push(event);
      groups[event.groupName] = group;
      return groups;
    }, {});
    return groupedEvents;
  };

  return (
    <>
      <Grid container direction={'column'} gap={2}>
        <Typography variant="h1" marginTop={'1em'}>
          {dataType}
        </Typography>
        <Typography variant="body1" marginBottom={'2em'}>
          Browse and join{' '}
          {dataType === 'Groups' ? 'social groups' : 'activities'}
        </Typography>
        <NavButtons isGroup={dataType === 'Groups'} />
        <FilterRow
          handleTextFilter={handleTextFilter}
          isEvent={dataType === 'Activities'}
          sortDataBy={sortDataBy}
          setSortDataBy={setSortDataBy}
        />

        {dataType === 'Activities' && sortDataBy === 'Group' ? (
          <>
            {Object.keys(handleGroupSearch(filteredData ?? [])).map(
              (key: any, index: number) => {
                return (
                  <Fragment key={index}>
                    <EventsOrGroupsPreview
                      title={key}
                      data={handleGroupSearch(filteredData ?? [])[key]}
                      type="activities"
                      disableShowMore={false}
                    />
                    <Divider />
                  </Fragment>
                );
              }
            )}
          </>
        ) : (
          <EventOrGroupList data={filteredData || []} />
        )}
      </Grid>
    </>
  );
}

export default EventOrGroupOverview;
