import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
} from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { AxiosError } from 'axios';
import LoadingIcon from 'components/LoadingIcon';
import {
  isAllValid,
  isContainingValue,
  isStrongPassword,
  isValidEmail,
  isValidName,
} from 'helpers/inputValidator';
import useHttpGet from 'hooks/useHttpGet';
import useHttpWithData from 'hooks/useHttpWithData';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { LocationBase } from 'types';

export default function SignUp() {
  const [, setSearchParams] = useSearchParams();

  const { send, status, error, isLoading } = useHttpWithData({
    url: `/users/external`,
    method: 'post',
  });

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    await send(formState);
    alert(
      'You are signed up! \nYou must verify your email before you can proceed log in'
    );
  };

  const [formState, setFormState] = useState({
    fullName: '',
    email: '',
    password: '',
    city: '',
  });

  const [hasErrorState, setHasErrorState] = useState({
    fullName: false,
    email: false,
    password: false,
    city: false,
  });

  useEffect(() => {
    if (status === 200) setSearchParams({ option: 'success' });
  }, [status]);

  const { data: locations } = useHttpGet<LocationBase[]>('/locations');

  function setValidFullName(name: string) {
    setHasErrorState((prev) => {
      return {
        ...prev,
        fullName: !isValidName(name),
      };
    });
  }

  function setValidEmail(email: string) {
    setHasErrorState((prev) => {
      return {
        ...prev,
        email: !isValidEmail(email),
      };
    });
  }

  function setValidPassword(password: string) {
    setHasErrorState((prev) => {
      return {
        ...prev,
        password: !isStrongPassword(password),
      };
    });
  }

  function setValidCity(city: string) {
    setHasErrorState((prev) => {
      return {
        ...prev,
        city: !isContainingValue(
          city,
          locations?.map((location) => location.city) || []
        ),
      };
    });
  }

  const errorResponse = (): string => {
    if (!error) return '';
    let response = (error as AxiosError).response;
    if (response?.status === 200) return '';
    if (response?.status === 500) return 'An error ocurred';
    return response?.data as string;
  };

  return (
    <>
      <Container maxWidth="xs">
        <Grid container direction="column" alignItems="center">
          <Avatar sx={{ m: 1, bgcolor: 'primary.light' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Register External User
          </Typography>
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{ mt: 3 }}
          >
            <Grid container spacing={2} gap={2} justifyContent="center">
              <TextField
                required
                error={hasErrorState.fullName}
                onChange={(e: any) => {
                  setFormState((prev) => {
                    setValidFullName(e.target.value);
                    return { ...prev, fullName: e.target.value };
                  });
                }}
                fullWidth
                id="fullname"
                label="Full Name"
                name="fullname"
                autoComplete="username"
              />
              <TextField
                error={hasErrorState.email}
                onChange={(e: any) => {
                  setFormState((prev) => {
                    setValidEmail(e.target.value);
                    return { ...prev, email: e.target.value };
                  });
                }}
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
              />
              <Tooltip
                title={
                  <>
                    Upper case: A-Z <br /> Lower case: a-z <br /> Number: 1-9
                    <br />
                    Symbol: !@#$%^&*_.
                  </>
                }
              >
                <TextField
                  required
                  error={hasErrorState.password}
                  onChange={(e: any) => {
                    setFormState((prev) => {
                      setValidPassword(e.target.value);
                      return { ...prev, password: e.target.value };
                    });
                  }}
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="new-password"
                />
              </Tooltip>
              <FormControl sx={{ width: '100%' }}>
                <InputLabel id="cityLabel">Location*</InputLabel>
                <Select
                  error={hasErrorState.city}
                  labelId="cityLabel"
                  id="city"
                  required
                  fullWidth
                  name="city"
                  label="Location"
                  value={formState.city}
                  onChange={(e: any) => {
                    setFormState((prev) => {
                      setValidCity(e.target.value);
                      return { ...prev, city: e.target.value };
                    });
                  }}
                >
                  {locations?.map((location) => (
                    <MenuItem key={location.locationId} value={location.city}>
                      {location.city}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Typography color={'error'}>{errorResponse()}</Typography>
              <Button
                disableElevation
                disabled={!isAllValid(formState, hasErrorState)}
                color="primary"
                type="submit"
                variant="contained"
                sx={{ mb: 2 }}
              >
                Create
              </Button>
              {isLoading && <LoadingIcon />}
            </Grid>
          </Box>
        </Grid>
      </Container>
    </>
  );
}
