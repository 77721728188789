import { Box, CardActionArea, Grid, Typography } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import GetImageUrl from 'helpers/GetImage';
import { isMobile } from 'react-device-detect';
import { useNavigate } from 'react-router';
import '../../styles/groupcardoverview.scss';

interface IGroupCard {
  socialGroupId: number;
  name: string;
  description: string;
  imageUrl: string;
}

function GroupOverviewCard({
  socialGroupId,
  name,
  description,
  imageUrl,
}: IGroupCard) {
  const navigate = useNavigate();
  const OverlayContent = () => (
    <Grid
      container
      direction="column"
      color="white"
      justifyContent="end"
      wrap="nowrap"
      style={{ height: '100%' }}
    >
      <Typography gutterBottom variant="h3">
        {name}
      </Typography>
      <Typography
        style={{
          width: '100%',
          overflow: 'hidden',
          maxHeight: '50px',
        }}
      >
        {description}
      </Typography>
    </Grid>
  );

  return (
    <Card
      sx={{
        borderRadius: '0.5em',
        position: 'relative',
        height: isMobile ? '280px' : '100%',
      }}
    >
      <CardActionArea
        sx={{ height: '100%', width: '100%' }}
        onClick={() => navigate(`/groups/${socialGroupId}`)}
      >
        <CardMedia
          component="img"
          image={GetImageUrl(imageUrl)}
          style={{ height: '100%' }}
          alt="description"
          sx={{ objectFit: 'cover' }}
        />
        <Box className="overlay" />
        <CardContent
          sx={{
            position: 'absolute',
            left: 0,
            right: 0,
            bottom: 0,
          }}
        >
          {OverlayContent()}
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

export default GroupOverviewCard;
