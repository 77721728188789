import React, { useState } from 'react';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { Button } from '@mui/material';
import axiosInstance from 'api/axiosInstance';
import { CompressImage } from 'helpers/CompressImage';

function UploadCarouselPic(props: { id: string }) {
  const { id } = props;
  const [file, setFile] = useState<any | null>(null);

  const config = { headers: { 'Content-Type': 'multipart/form-data' } };

  const { REACT_APP_API_URI } = process.env;

  async function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    const selectedFile = e.target.files?.[0];
    if (selectedFile) {
      setFile(await CompressImage(selectedFile));
    }
  }

  async function handleUpload() {
    if (!file) {
      return;
    }

    try {
      const formData = new FormData();
      formData.append('file', file!);
      formData.append('fileName', 'Carousel Image');

      await axiosInstance.post(
        `${REACT_APP_API_URI}/api/blobstorage/Upload-Carousel/${id}`,
        formData,
        config
      );
      window.location.reload();
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  }

  return (
    <div className="Upload">
      <div className="upload-content">
        <div>
          <h3 className="title" style={{ textAlign: 'center' }}>
            Upload Carousel Picture
          </h3>
        </div>
        <>
          <input
            type="file"
            id="button-file"
            hidden
            accept="image/png, image/jpeg, image/jpg, image/webp"
            onChange={handleChange}
          ></input>
          {file && (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginBottom: '1rem',
              }}
            >
              <img
                style={{ height: '15rem', alignItems: 'center', width: 'auto' }}
                src={URL.createObjectURL(file)}
                alt="Uploaded"
              />
            </div>
          )}

          <div
            style={{ display: 'flex', justifyContent: 'center', gap: '2rem' }}
          >
            <label htmlFor="button-file">
              <Button
                disableElevation
                variant="outlined"
                component="span"
                sx={{
                  width: '100%',
                  color: '#828282',
                  borderColor: '#828282',
                }}
              >
                <FileUploadIcon />
                Choose a file
              </Button>
            </label>

            <Button
              disableElevation
              variant="contained"
              color="primary"
              onClick={handleUpload}
            >
              Upload
            </Button>
          </div>
        </>
      </div>
    </div>
  );
}

export default UploadCarouselPic;
