import { Card, CardMedia, Grid } from '@mui/material';
import GetImageUrl from 'helpers/GetImage';
import { isMobile } from 'react-device-detect';
import BannerContent from './BannerContent';

export interface SocialGroupInterface {
  socialGroupId?: number;
  name: string;
  imageUrl: string;
  description: string;
  memberCount?: number;
  LocationId?: number;
  groupAdmin?: {
    fullName: string;
    email: string;
  };
  isMember: any;
  reloadData: any;
  groupLocation: any;
}

function Banner(props: SocialGroupInterface) {
  const { imageUrl } = props;

  return (
    <>
      <Grid id="banner" xs={12} item container>
        <Card
          sx={{
            backgroundColor: 'white',
            display: 'flex',
            flexWrap: 'wrap-reverse',
            boxShadow: 'none',
            my: 2,
            borderRadius: '1rem',
            width: '100%',
          }}
        >
          <Grid margin="0 auto" container item xs={12}>
            <Grid item lg={6} xs={12}>
              <BannerContent {...props} />
            </Grid>
            <Grid
              item
              container
              lg={6}
              xs={12}
              className="grouppage-image-container"
              justifyContent="center"
              alignItems={'center'}
              alignSelf="center"
            >
              <CardMedia
                sx={{
                  height: isMobile ? '15rem' : '25rem',
                  width: '100%',
                  borderRadius: '1rem',
                  objectFit: 'cover',
                }}
                component="img"
                image={GetImageUrl(imageUrl)}
                alt="Group Logo"
              />
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </>
  );
}

export default Banner;
