import { Box, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import EventOrGroupCard from 'components/EventOrGroupCard';

function EventOrGroupList(props: { data: any[] }) {
  const { data } = props;
  return (
    <>
      {data?.length > 0 ? (
        <Grid container rowSpacing={4} marginBottom="2em" marginTop="0.1em">
          {data.map((o: any, idx: number) => (
            <Grid item lg={4} md={6} xs={12} key={idx}>
              <EventOrGroupCard data={o} />
            </Grid>
          ))}
        </Grid>
      ) : (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          border="2px dashed rgba(0, 0, 0, 0.12)"
          borderRadius="8px"
          padding="3em"
          marginTop="2em"
        >
          <Typography variant="h5" align="center">
            No results found.
          </Typography>
        </Box>
      )}
    </>
  );
}

export default EventOrGroupList;
