import { swActivatedPublisher } from 'helpers/pubsub';

export function registerSW() {
  if (!navigator?.serviceWorker) {
    throw new Error("This browser doesn't support service workers");
  }

  if (!window?.PushManager) {
    throw new Error("This browser doesn't support web push notifications");
  }

  navigator.serviceWorker.register('/sw.js', {
    type: 'module',
    scope: '/',
  });

  navigator.serviceWorker.ready.then((registration) => {
    swActivatedPublisher.publish({
      timestamp: new Date(),
      payload: registration,
    });
  });
}

export async function unregisterSW() {
  if (navigator?.serviceWorker) {
    return navigator.serviceWorker.ready.then((r) => r.unregister());
  }
}
