import {
  Grid,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Typography,
} from '@mui/material';
import useHttpGet from 'hooks/useHttpGet';
import { isMobile } from 'react-device-detect';
import { useNavigate } from 'react-router';
import { Location } from 'types';
import '../../styles/Location.scss';

function LocationCollection() {
  const { data: alllocations } = useHttpGet<Location[]>(`/locations`);
  const navigate = useNavigate();
  if (!alllocations?.length)
    return <Typography variant="h4">No data</Typography>;

  return (
    <>
      <Grid
        container
        alignItems="center"
        direction="column"
        id="explore-location-overview"
      >
        <Typography variant="h2">Our Locations</Typography>
        <Typography color="text.secondary" textAlign="center">
          CapSocial is present at these Capgemini locations.
        </Typography>
        <ImageList cols={isMobile ? 2 : 3} gap={10} sx={{ width: '100%' }}>
          {alllocations.map((location) => (
            <ImageListItem key={location.locationId} className="location-card">
              <img src={location.imageUrl} alt={location.city} />
              <ImageListItemBar
                title={<Typography variant="h3">{location.city}</Typography>}
                className="image-list-itembar"
                onClick={() =>
                  navigate(`/locations/${location.city.toLowerCase()}`)
                }
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Grid>
    </>
  );
}

export default LocationCollection;

/*
<ImageListItem key={location.locationId} className="location-card">
  <img src={location.imageUrl} alt={location.city} height="100px" />
  <ImageListItemBar
    title={<Typography variant="h3">{location.city}</Typography>}
    className="image-list-itembar"
    onClick={() =>
      navigate(`/locations/${location.city.toLowerCase()}`)
    }
  />
</ImageListItem>
*/
