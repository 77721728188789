import { Box } from '@mui/material';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

const QuestionTitle = () => (
  <Grid item md={4}>
    <Typography
      textAlign={'center'}
      fontWeight={'bold'}
      variant="h4"
      color="primary.main"
    >
      What is CapSocial?
    </Typography>
  </Grid>
);

const AnswerTitle = () => (
  <Grid item>
    <Typography textAlign={'center'} variant="h2" fontWeight={'bold'}>
      A new social platform{' '}
      <Box sx={{ backgroundColor: 'primary.light', display: 'inline-block' }}>
        built for you
      </Box>
    </Typography>
  </Grid>
);

const BodyText = () => (
  <Grid item>
    <Typography textAlign={'center'}>
      CapSocial is a platform built with one purpose - to foster social
      activities and allow people to connect. Search, attend and create
      activities and groups and get to know the people around you.
    </Typography>
  </Grid>
);

function StaticInfoSection() {
  return (
    <>
      <Grid item md={10}>
        <Grid
          container
          direction="column"
          gap={1}
          justifyContent={'center'}
          px={2}
        >
          {QuestionTitle()}
          {AnswerTitle()}
          {BodyText()}
        </Grid>
      </Grid>
    </>
  );
}

export default StaticInfoSection;
