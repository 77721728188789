import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import CitySelect from 'components/CitySelect';
import GroupSelect from 'pages/AllEvents/GroupSelect';
import { ChangeEvent } from 'react';

interface IFilterRows {
  handleTextFilter?: (e: ChangeEvent<HTMLInputElement>) => void;
  isEvent: boolean;
  sortDataBy: any;
  setSortDataBy: any;
}

function FilterRow(props: IFilterRows) {
  const { handleTextFilter, isEvent, sortDataBy, setSortDataBy } = props;
  return (
    <>
      <Grid container item gap={2} my={1}>
        <Grid item xs={12} md={3}>
          <TextField
            fullWidth
            label="Search"
            minRows={1}
            onChange={handleTextFilter}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <CitySelect />
        </Grid>

        {isEvent && (
          <Grid item xs={12} md={3}>
            <GroupSelect
              sortDataBy={sortDataBy}
              setSortDataBy={setSortDataBy}
            />
          </Grid>
        )}
      </Grid>
      <Divider />
    </>
  );
}

export default FilterRow;
