import { alpha, darken, lighten, ThemeOptions } from '@mui/material';
import { atom, selector } from 'recoil';

const customTonalOffset = {
  light: 0.7, // Primary.light => main with 30% opacity
  dark: 0.25, // Primary.dark => main with 25% dark opacity added
};
export const allCityColor = '#0070AD'; // Capgemini blue
export const sideNavColor = '#272936'; // Side nav color

export const themeColorState = atom({
  key: 'themeColorState',
  default: localStorage.getItem('theme') || ('#000' as string),
});

export const themeState = selector({
  key: 'themeState',
  get: ({ get }) => {
    let themeColor = get(themeColorState);
    return {
      palette: {
        primary: {
          main: themeColor,
        },
        secondary: {
          main: sideNavColor,
          light: alpha(sideNavColor, 0.88),
        },
        info: {
          main: lighten(themeColor, 0.9),
          dark: darken(themeColor, 0.3),
        },
        tonalOffset: customTonalOffset,
      },
      components: {
        MuiButton: {
          styleOverrides: {
            root: ({ theme }) => ({
              '&.MuiButton-contained': {
                padding: '10px 28px',
                borderRadius: '8px',
              },
              '&.MuiButton-outlined': {
                padding: '10px 28px',
                borderRadius: '8px',
              },

              '&.MuiButton-containedSecondary': {
                backgroundColor: theme.palette.secondary.light,
              },
              '&.MuiButton-containedSecondary:hover': {
                backgroundColor: theme.palette.secondary.main,
              },
            }),

            textPrimary: {
              padding: '10px 28px',
              borderRadius: '8px',
            },
          },
        },
      },
      typography: {
        button: {
          textTransform: 'none',
          fontWeight: 500,
          fontSize: '16px',
        },
        fontFamily: 'Ubuntu',
        h1: {
          fontSize: '48px',
          fontWeight: 700,
        },
        h2: {
          fontSize: '34px',
          fontWeight: 700,
        },
        h3: {
          fontSize: '24px',
          fontWeight: 600,
        },
        h4: {
          fontSize: '20px',
          fontWeight: 500,
        },
        h5: {
          fontSize: '18px',
          fontWeight: 400,
        },
        h6: {
          fontSize: '16px',
        },
        body1: {
          fontSize: '16px',
          fontWeight: 400,
        },
        body2: {
          fontSize: '14px',
          fontWeight: 500,
        },
        subtitle1: {
          fontSize: '14px',
        },
        subtitle2: {
          fontSize: '12px',
        },
      },
    } as ThemeOptions;
  },
});
