import { Loader } from '@googlemaps/js-api-loader';
import { Box } from '@mui/system';
import { useEffect, useRef, useState } from 'react';

const key = process.env.REACT_APP_MAPS_KEY;
export default function MapWithSearch(props: {
  query: string;
  setResults: (prev: string[]) => void;
}) {
  const [sentRequests, setSentRequests] = useState<number>(0);
  const map = useRef<google.maps.Map | null>(null);
  const mapContainer = useRef<HTMLDivElement>(null);
  const markers = useRef<google.maps.Marker[]>([]);
  const infowindow = useRef<google.maps.InfoWindow | null>(null);
  const service = useRef<google.maps.places.PlacesService | null>(null);

  function initMap(): void {
    const loader = new Loader({
      apiKey: key!,
      libraries: ['places'],
      version: 'weekly',
    });

    loader
      .load()
      .then(() => {
        map.current = new google.maps.Map(mapContainer.current as HTMLElement, {
          center: { lat: 60.711, lng: 8.958 },
          // restriction: {
          //   latLngBounds: {},
          //   strictBounds: false,
          // },
          zoom: 6,
          disableDefaultUI: true,
          zoomControl: true,
        });
        infowindow.current = new google.maps.InfoWindow();
        service.current = new google.maps.places.PlacesService(map.current);
      })
      .catch((e) => {
        console.error(e);
      });
  }

  function executeSearch(query: string): void {
    if (!query || !map.current || !service.current) return;

    setSentRequests((prev) => prev + 1);
    const request = {
      query,
      fields: ['name', 'geometry', 'formatted_address'],
    };
    service.current.findPlaceFromQuery(
      request,
      (
        results: google.maps.places.PlaceResult[] | null,
        status: google.maps.places.PlacesServiceStatus
      ) => {
        if (status === google.maps.places.PlacesServiceStatus.OK && results) {
          clearMarkers();
          markers.current = results.map((result) => createMarker(result));
          props.setResults(results.map((result) => result.formatted_address!));
          map.current?.setCenter(results[0].geometry!.location!);
          map.current?.setZoom(15);
        }
      }
    );
  }

  function clearMarkers() {
    if (!markers) return;
    for (let marker of markers.current) {
      marker.setMap(null);
    }
  }

  function createMarker(
    place: google.maps.places.PlaceResult
  ): google.maps.Marker {
    if (!place.geometry || !place.geometry.location)
      throw new Error('Invalid location');

    const marker = new google.maps.Marker({
      map: map.current,
      position: place.geometry.location,
    });

    google.maps.event.addListener(marker, 'click', () => {
      infowindow.current?.setContent(place.name || '');
      infowindow.current?.open(map.current);
    });

    return marker;
  }

  useEffect(() => {
    initMap();
  }, []);

  useEffect(() => {
    if (sentRequests >= 10) return;
    const id = setTimeout(() => executeSearch(props.query), 800);
    return () => clearTimeout(id);
  }, [props.query]);

  return <Box ref={mapContainer} style={{ height: '300px', width: '100%' }} />;
}
