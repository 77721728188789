import ArrowForwardIcon from '@mui/icons-material/ArrowForwardIos';
import { Button, Grid, Typography } from '@mui/material';
import useUser from 'hooks/useUser';
import { useNavigate } from 'react-router';

export default function ManagementPage() {
  const { isAdmin, isCoreMemberOrAdmin } = useUser();

  const navigate = useNavigate();

  const ViewEventsButton = (linkTo: string, buttonText: string) => (
    <Button
      disableElevation
      endIcon={<ArrowForwardIcon />}
      sx={{ color: 'primary.main', width: '300px' }}
      onClick={() => navigate(`/management/${linkTo}`)}
    >
      <Typography variant="h3">Manage {buttonText}</Typography>
    </Button>
  );

  return (
    <>
      <Grid
        container
        alignItems="center"
        direction="column"
        marginTop="3em"
        spacing="2em"
      >
        {isAdmin && <Grid item>{ViewEventsButton('users', 'users')}</Grid>}
        {isCoreMemberOrAdmin && (
          <Grid item>{ViewEventsButton('events/past', 'past activities')}</Grid>
        )}
        {isCoreMemberOrAdmin && (
          <Grid item>{ViewEventsButton('events', 'activities')}</Grid>
        )}
        {isCoreMemberOrAdmin && (
          <Grid item>{ViewEventsButton('groups', 'groups')}</Grid>
        )}
      </Grid>
    </>
  );
}
