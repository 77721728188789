import { CardActionArea, Grid, Typography } from '@mui/material';
import LoadingIcon from 'components/LoadingIcon';
import UserAvatar from 'components/UserAvatar';
import useUser from 'hooks/useUser';
import { useNavigate } from 'react-router';

function PicturePart(user: any) {
  return (
    <Grid item xs={4}>
      <UserAvatar fullName={user.fullName} imageUrl={user.imageUrl} size={40} />
    </Grid>
  );
}

function NamePart(name: string) {
  return (
    <Grid item xs={12}>
      <Typography
        variant="body2"
        sx={{
          color: '#FFF',
        }}
      >
        {name}
      </Typography>
    </Grid>
  );
}

export default function ProfileBox(props: { onClose?: () => void }) {
  const user = useUser();
  const { onClose } = props;
  const navigate = useNavigate();

  if (user.isLoading) return <LoadingIcon />;

  if (user.isAuthenticated) {
    return (
      <Grid
        item
        mt={2}
        xs={10}
        borderRadius={2}
        sx={{
          backgroundColor: 'white',
        }}
      >
        <Grid
          borderRadius={1.5}
          sx={{
            backgroundColor: 'secondary.light',
            '&:hover': {
              opacity: 1.5,
            },
          }}
        >
          <CardActionArea
            onClick={() => {
              onClose?.();
              navigate(`/user`);
            }}
          >
            <Grid
              container
              my={1}
              justifyContent="center"
              alignItems={'center'}
            >
              <Grid item xs={3}>
                {PicturePart(user)}
              </Grid>
              <Grid item xs={8}>
                {NamePart(user?.fullName || '')}
              </Grid>
            </Grid>
          </CardActionArea>
        </Grid>
      </Grid>
    );
  }

  return <></>;
}
