import { Grid, Typography } from '@mui/material';
import UserAvatar from 'components/UserAvatar';
import './MemberCard.scss';

function TextPart(name: string) {
  return (
    <Grid
      className="membercard-text-container"
      item
      justifyContent="center"
      width={'100%'}
    >
      <Typography
        className="membercard-text-fix"
        textAlign="center"
        variant="body1"
      >
        {name}
      </Typography>
    </Grid>
  );
}

export interface MemberCardInterface {
  fullName: string;
  imageUrl?: string;
  AppUserId: string;
}

export default function MemberCard(props: { user: MemberCardInterface }) {
  const { user } = props;

  return (
    <Grid item xs={6} md={2}>
      <Grid container justifyContent={'center'}>
        <UserAvatar fullName={user.fullName} imageUrl={user.imageUrl || ''} />
        {TextPart(user.fullName)}
      </Grid>
    </Grid>
  );
}
