import { Checkbox, FormControlLabel, Grid, Typography } from '@mui/material';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { enGB } from 'date-fns/locale';
import { SetStateFunc } from 'helpers/utilities';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { SocialEventForm } from 'types';

export const isValidDate = (
  checkDate: Date,
  formState: SocialEventForm,
  setErrorMsg: any
) => {
  let today = moment();
  let eventStart = formState.startTime;
  let dateToCheck = moment(checkDate);

  // Not a completed date
  if (!dateToCheck.isValid()) {
    setErrorMsg('Invalid date');
    return false;
  }

  // Date is after event start
  if (dateToCheck.isAfter(eventStart)) {
    setErrorMsg('Date needs to be before event start');
    return false;
  }

  // Date is before today
  if (dateToCheck.isBefore(today)) {
    setErrorMsg('Date needs to be after today');
    return false;
  }

  setErrorMsg('');
  return true;
};

function PublishAtForm(props: {
  eventFormState: SocialEventForm;
  setEventFormState: SetStateFunc<SocialEventForm>;
  hasErrorState: any;
  setHasErrorState: any;
}) {
  const { eventFormState, setEventFormState, hasErrorState, setHasErrorState } =
    props;

  const [hasPublishDate, setHasPublishDate] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  useEffect(() => {
    if (eventFormState.publishDate != null) {
      setHasPublishDate(true);
    }
  }, [eventFormState.publishDate]);

  const handlePublishChange = () => {
    if (hasPublishDate) {
      setHasErrorState((prev: any) => ({
        ...prev,
        publishDate: false,
      }));
      setEventFormState((prev: SocialEventForm) => ({
        ...prev,
        publishDate: null,
      }));
    } else {
      setHasErrorState((prev: any) => ({
        ...prev,
        publishDate: true,
      }));
      setErrorMessage('Choose a date');
    }
    setHasPublishDate(!hasPublishDate);
  };

  const handleDateChange = (newValue: Date | null) => {
    if (newValue) {
      setHasErrorState((prev: any) => ({
        ...prev,
        publishDate: !isValidDate(newValue, eventFormState, setErrorMessage),
      }));
      setEventFormState((prev: SocialEventForm) => ({
        ...prev,
        publishDate: moment(newValue),
      }));
    }
  };

  return (
    <Grid container item gap={2}>
      <Typography variant="h3">Automatic Publishing</Typography>
      <Grid container alignItems="center">
        <FormControlLabel
          control={
            <Checkbox
              color="secondary"
              checked={hasPublishDate}
              onChange={handlePublishChange}
            />
          }
          label="Do you want the activity to be published automatically?"
        />
        {hasPublishDate && (
          <LocalizationProvider
            dateAdapter={AdapterDateFns}
            adapterLocale={enGB}
          >
            <DateTimePicker
              format="dd/MM/yyyy HH:mm"
              label={`Publish at`}
              ampm={false}
              value={eventFormState.publishDate?.toDate()}
              minutesStep={60}
              maxDateTime={eventFormState.startTime.toDate()}
              minDateTime={new Date()}
              onChange={handleDateChange}
              slotProps={{
                textField: {
                  error: hasErrorState.publishDate,
                  helperText: errorMessage,
                  fullWidth: true,
                },
              }}
            />
          </LocalizationProvider>
        )}
      </Grid>
    </Grid>
  );
}

export default PublishAtForm;
