import { Grid } from '@mui/material';
import { Fragment } from 'react';
import { isMobile } from 'react-device-detect';
import { SocialEventCalendar } from 'types';
import '../../styles/schedule.scss';
import EventListByWeek from './EventListByWeek';

function EventContainer(props: {
  events: Map<string, Map<string, SocialEventCalendar[]>> | {};
  attendingEventIds: number[];
}) {
  const { events, attendingEventIds } = props;

  return (
    <>
      {Object.entries(events).map((yearEntry: any, yearIndex: number) => (
        <Fragment key={yearIndex}>
          <Grid
            mb={2}
            container
            direction="column"
            alignItems={isMobile ? 'center' : ''}
          >
            <EventListByWeek
              entry={yearEntry}
              attendingEventIds={attendingEventIds ?? []}
            />
          </Grid>
        </Fragment>
      ))}
    </>
  );
}

export default EventContainer;
