import { initializeApp } from 'firebase/app';
import { apiBaseUrl, isDev } from 'api/common';

import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { pushRecievedPublisher } from 'helpers/pubsub';

// Initialize Firebase
const app = initializeApp({
  apiKey: process.env.REACT_APP_FCM_API_KEY,
  authDomain: 'capsocial.firebaseapp.com',
  projectId: 'capsocial',
  storageBucket: 'capsocial.appspot.com',
  messagingSenderId: '46943351226',
  appId: '1:46943351226:web:9d0cd52336f28c0a8c6d83',
  measurementId: 'G-8XB2Z2S7EE',
});

const messaging = getMessaging(app);
onMessage(messaging, (payload) => {
  pushRecievedPublisher.publish({
    timestamp: new Date(),
    payload,
  });
});

function getFCMToken(registration: ServiceWorkerRegistration | null) {
  const publicVapidKey = process.env.REACT_APP_PUBLIC_VAPID_KEY ?? '';
  return !registration
    ? Promise.resolve('')
    : getToken(messaging, {
        vapidKey: publicVapidKey,
        serviceWorkerRegistration: registration,
      }).catch((err) => '');
}

export async function updateTokenOnServer(
  fcmToken: string,
  accessToken: string
) {
  console.log('Updating device token on server...');
  return fetch(`${apiBaseUrl}/notifications/save-token`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify({ token: fcmToken, issuedAt: new Date() }),
  }).catch();
}

export function createInitPush(swRegistration: ServiceWorkerRegistration) {
  return async (accessToken: string, permission: NotificationPermission) => {
    if (permission !== 'granted') {
      if (isDev) console.warn('Not permitted to show notifications');
      return;
    }

    const fcmToken = await getFCMToken(swRegistration);
    await updateTokenOnServer(fcmToken, accessToken);
  };
}
