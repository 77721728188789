import { Box, CardContent, Typography } from '@mui/material';
import useUser from 'hooks/useUser';
import { isMobile } from 'react-device-detect';
import { useParams } from 'react-router';
import { SocialGroupInterface } from './Banner';
import ContentHeader from './ContentHeader';
import JoinGroupButton from './JoinGroupButton';


function BannerContent(props: SocialGroupInterface) {
  const { description, reloadData } = props;
  const { isAuthenticated } = useUser();
  const {id: socialGroupId } = useParams();

  return ( 
    <>
      <CardContent sx={{ flex: '1 0 auto', paddingLeft: '0px' }}>
        <ContentHeader {...props} />
        <Typography
            marginBottom={'2em'}
            align={isMobile ? 'center' : 'inherit'}
            sx={{ whiteSpace: 'pre-line', wordBreak: 'break-word' }}
        >
            {description}
        </Typography>
        {isAuthenticated && (
          <Box
              display="flex"
              justifyContent={isMobile ? 'center' : ''}
              alignItems={'center'}
          >
          <JoinGroupButton
              socialGroupId= { socialGroupId }
              displayText = { true }
              reloadData = {reloadData}
          />
          </Box>
        )}
      </CardContent>
    </>
  );
}

export default BannerContent;
