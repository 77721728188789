import CancelIcon from '@mui/icons-material/Cancel';
import { Box, Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { SocialEventForm } from 'types';
import { CompressImage, GiveFileImageType } from '../../helpers/CompressImage';
import GetImageUrl from '../../helpers/GetImage';
import '../../styles/fileHandler.scss';
import ChooseFile from './ChooseFile';
import ChooseStock from './ChooseStock';

function ImageDrop(props: {
  errorState?: any;
  setErrorState?: any;
  setFormState: any;
  prevImage?: string;
}) {
  const { errorState, setErrorState, setFormState, prevImage } = props;
  const [file, setFile] = useState(null);

  const [preview, setPreview] = useState('');

  const fileSelectedHandler = async (event: any) => {
    const file = event.target.files[0];
    if (file.size > 5e6 * 8) {
      alert(
        'The file size is too big. Choose a smaller file size and try again'
      );
    } else {
      setFile(await CompressImage(file));
    }
  };

  const stockSelectedHandler = async (file: File) => {
    const newFile = GiveFileImageType(file);
    setFile(await CompressImage(newFile));
  };

  useEffect(() => {
    if (!file) {
      setPreview('');
      setFormState((prev: SocialEventForm) => ({
        ...prev,
        file: new Blob(),
      }));
      return;
    }
    setFormState((prev: SocialEventForm) => ({
      ...prev,
      file: file,
    }));

    const objectUrl = URL.createObjectURL(file);
    setPreview(objectUrl);
    return () => URL.revokeObjectURL(objectUrl);
  }, [file]);

  useEffect(() => {
    const isPng = new RegExp('[.*.png.*]');
    const url = isPng.test(prevImage ?? '')
      ? GetImageUrl(prevImage ?? '')
      : prevImage;
    setPreview(url ?? '');
  }, []);

  useEffect(() => {
    setErrorState((prev: any) => ({ ...prev, file: !preview }));
  }, [preview]);

  function removeImage() {
    setFile(null);
    setPreview('');
  }

  // preview equals the name of a PNG file and not an actual image in edit

  const ChosenFile = () => {
    return (
      <>
        {preview ? (
          <Grid container item className="container-selected-image">
            <img
              alt={'header'}
              src={preview}
              className="previewSelectedImage"
            />
            <CancelIcon
              className="cancel-icon"
              onClick={() => {
                removeImage();
              }}
            />
          </Grid>
        ) : (
          <ChooseFile fileSelectedHandler={fileSelectedHandler} />
        )}
      </>
    );
  };

  return (
    <Grid
      container
      item
      direction="column"
      className="uploadFile"
      md={10}
      sx={{ border: errorState.file ? '1px solid red' : '' }}
    >
      <Grid item>
        <Typography variant="h3" pt={2} pb={2}>
          Upload Image
          <Box component="span" color="red" ml={0.5}>
            *
          </Box>
        </Typography>
      </Grid>
      <Grid container item direction="row" gap={5}>
        <Grid container item className="uploadImageContainer" sm={5} md>
          <ChosenFile />
        </Grid>
        <Grid item sm={5} md>
          <ChooseStock stockSelectedHandler={stockSelectedHandler} />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default ImageDrop;
