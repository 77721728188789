import { Grid } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

function LoadingIcon() {
  return (
    <Grid justifyContent={'center'} container>
      <CircularProgress />
    </Grid>
  );
}

export default LoadingIcon;
