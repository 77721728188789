function GetImageUrl(filename: string) {
  if (!filename) return '';

  const isHttp = new RegExp('^(http|https://)');

  const { REACT_APP_API_URI } = process.env;
  const url = `${REACT_APP_API_URI}/api/blobstorage/download-file/${filename}`;

  return isHttp.test(filename) ? filename : url;
}

export default GetImageUrl;
