import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, Button, Grid, Tooltip, Typography } from '@mui/material';
import UserAvatar from 'components/UserAvatar';
import UserSearch from 'components/UserSearch/UserSearch';
import useHttpGet from 'hooks/useHttpGet';
import { useState } from 'react';
import { useParams } from 'react-router';
import { EditSocialGroup, UserWithImage } from 'types';

function GroupCoreMembersInfoForm(props: {
  groupFormState: EditSocialGroup;
  setGroupFormState: any;
  setHasErrorState: any;
}) {
  const { groupFormState, setGroupFormState, setHasErrorState } = props;

  const { groupId } = useParams();

  const { data: members } = useHttpGet<UserWithImage[]>(`/users`);

  const [user, setUser] = useState<UserWithImage | null>(null);

  function addCoreMember() {
    if (user) {
      if (
        groupFormState.groupCoreMembers?.find(
          (o) => o.appUserId === user.appUserId
        )
      ) {
        return;
      }

      setGroupFormState((prev: EditSocialGroup) => ({
        ...prev,
        groupCoreMembers: groupFormState.groupCoreMembers?.concat([user]),
      }));
    }
  }

  function removeCoreMember(member: UserWithImage) {
    setGroupFormState((prev: EditSocialGroup) => ({
      ...prev,
      groupCoreMembers: groupFormState.groupCoreMembers?.filter(
        (coreMember) => coreMember.appUserId !== member.appUserId
      ),
    }));
    if (member === groupFormState.groupLeadMember) {
      setHasErrorState((prev: any) => ({
        ...prev,
        groupLeadMember: true,
      }));
      setGroupFormState((prev: EditSocialGroup) => ({
        ...prev,
        groupLeadMember: null,
      }));
    }
  }

  return (
    <>
      <Grid container direction={'column'} gap={2}>
        <Typography variant="h3">
          Group administrators
          <Box component="span" color="red" ml={0.5}>
            *
          </Box>
        </Typography>

        <Grid container gap={2} marginBottom={3}>
          <Grid item xs={12} md={4}>
            <UserSearch
              setUser={(user) => {
                setUser(user);
              }}
              users={members}
            />
          </Grid>
          {groupId && (
            <Grid item my="auto">
              <Tooltip
                title="Able to manage events for the group"
                placement="right"
              >
                <InfoOutlinedIcon />
              </Tooltip>
            </Grid>
          )}
          {user && (
            <Grid item xs={12} md={2}>
              <Button
                disableElevation
                variant="contained"
                className="create-event-button"
                fullWidth
                onClick={addCoreMember}
              >
                Add
              </Button>
            </Grid>
          )}
        </Grid>

        <Grid container direction="column" rowSpacing={4}>
          {groupFormState.groupCoreMembers?.map((coreMember, index) => {
            return (
              <Grid container key={index} marginBottom={1}>
                <Grid item xs={12} md={6}>
                  <Grid container alignItems="center" marginBottom={1}>
                    <Grid item xs={3}>
                      <UserAvatar
                        fullName={coreMember.fullName}
                        imageUrl={coreMember.imageUrl || ''}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Typography>{coreMember.fullName}</Typography>
                      <Typography>{coreMember.email}</Typography>
                    </Grid>
                  </Grid>
                  <Button
                    disableElevation
                    variant="contained"
                    className="create-event-button"
                    fullWidth
                    sx={{
                      color: 'black',
                      '&:hover': {
                        backgroundColor: '#f44336',
                      },
                    }}
                    color="primary"
                    onClick={(member) => removeCoreMember(coreMember)}
                  >
                    Remove
                  </Button>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    </>
  );
}

export default GroupCoreMembersInfoForm;
