import { Grid, Link, Typography } from '@mui/material';
import useUser from 'hooks/useUser';
import { isMobile } from 'react-device-detect';
import { SocialGroupInterface } from './Banner';

function ContentHeader(props: SocialGroupInterface, joined?: boolean) {
  const { name, memberCount, groupAdmin, groupLocation } = props;
  const { isAuthenticated } = useUser();

  return (
    <>
      <Grid
        container
        direction={'column'}
        alignContent={isMobile ? 'center' : ''}
        marginBottom={'2em'}
      >
        <Typography variant="h1" fontWeight={'bold'}>
          {name}
        </Typography>
        <Typography
          variant="subtitle1"
          mb={4}
          sx={{ textTransform: 'capitalize', color: '#838383' }}
        >
          {groupLocation ? groupLocation.city : ''}
        </Typography>
        {isAuthenticated && (
          <>
            <Typography variant="h4">Members: {memberCount}</Typography>
            <Typography variant="h4">
              Contact:{' '}
              <Link
                href={`mailto:${groupAdmin?.email}`}
                color="rgba(0, 0, 0, 0.87)"
              >
                {groupAdmin?.fullName}
              </Link>
            </Typography>
          </>
        )}
      </Grid>
    </>
  );
}

export default ContentHeader;
