import { Typography } from '@mui/material';

function GroupName(props: { name: string }) {
  const { name } = props;
  return (
    <Typography gutterBottom variant="h4" sx={{ marginTop: 5 }}>
      {name}
    </Typography>
  );
}

function GroupDescription(props: { description: string }) {
  let { description } = props;

  return (
    <Typography
      variant="body1"
      style={{
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        height: '140px',
        color: '#838383',
      }}
      sx={{ whiteSpace: 'pre-line', wordBreak: 'break-word' }}
    >
      {description}
    </Typography>
  );
}

export { GroupDescription, GroupName };
