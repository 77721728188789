import { Box, Button, Grid, Link, Typography } from '@mui/material';
import RotatingCities from 'components/InformationBanner/RotatingCities';
import { isMobile } from 'react-device-detect';
import Typewriter from 'typewriter-effect';

const aboutText = `Fun and Team Spirit are among our seven values and social activities are key to attracting and retaining talents across all our locations. 
  With CapSocial we make it easy for you to get an overview of what's happening - in your own location and elsewhere. Get ready to explore!`;

const IntroductionTextComponent = (props: { location: any }) => {
  const textAlignMobile = isMobile ? 'center' : 'left';
  const { location } = props;

  const scrollToSection = (htmlId: string) => {
    document.getElementById(htmlId)?.scrollIntoView({ behavior: 'smooth' });
  };
  return (
    <Grid container gap={2} mx="auto" direction="column">
      {location && (
        <Grid item>
          <Typography
            variant="h3"
            textAlign={textAlignMobile}
            color="primary.main"
            sx={{ fontWeight: '400' }}
          >
            {location.name}
          </Typography>
          <Typography variant="h6">
            Lead:{' '}
            <Link
              href={`mailto:${location.lead?.email}`}
              color="rgba(0, 0, 0, 0.87)"
            >
              {location.lead?.fullName}
            </Link>
          </Typography>
        </Grid>
      )}
      <Grid item>
        <Typography variant="h1" textAlign={textAlignMobile}>
          {location && (
            <>
              {'Welcome to '}
              <br />
              <Box
                sx={{
                  display: 'inline-block',
                  backgroundColor: location.color + '4C', // 4C corresponds to 30%
                }}
              >
                <Typewriter
                  onInit={(typewriter) =>
                    typewriter.typeString(location.city).start()
                  }
                />
              </Box>
            </>
          )}
          {!location && (
            <div>
              All happenings, <br />
              <RotatingCities />
            </div>
          )}
        </Typography>
      </Grid>
      <Grid item>
        <Typography textAlign={textAlignMobile}>
          {location ? location.description : aboutText}
        </Typography>
      </Grid>
      <Grid container item xs={12} justifyContent={isMobile ? 'center' : ''}>
        <Button
          disableElevation
          variant="contained"
          color="primary"
          onClick={() =>
            scrollToSection(
              `explore-${location ? 'group' : 'location'}-overview`
            )
          }
        >
          Explore {location ? 'groups' : 'locations'}
        </Button>
      </Grid>
    </Grid>
  );
};

export default IntroductionTextComponent;
