import { Box, Typography } from '@mui/material';
import { isValidDescription } from 'helpers/inputValidator';
import {
  BtnBold,
  BtnBulletList,
  BtnItalic,
  BtnNumberedList,
  BtnUnderline,
  Editor,
  EditorProvider,
  Separator,
  Toolbar,
} from 'react-simple-wysiwyg';

function RichTextEditor(props: {
  description: any;
  onChange: any;
  setHasErrorState: any;
}) {
  const { description, onChange } = props;

  const hasValidLength = (description: string) => {
    if (description.length <= 1500) {
      return true;
    }
  };

  return (
    <>
      <Box
        component="div"
        sx={{
          borderRadius: '8px',
        }}
      >
        <EditorProvider>
          <Editor value={description} onChange={onChange}>
            <Toolbar>
              <Typography padding={1} mr={3}>
                Description
              </Typography>
              <BtnBold />
              <BtnItalic />
              <BtnUnderline />
              <Separator />
              <BtnNumberedList />
              <BtnBulletList />
            </Toolbar>
          </Editor>
        </EditorProvider>
        {!hasValidLength(description) && (
          <Typography padding={0.5} color={'error'}>
            Maximum character limit reached
          </Typography>
        )}
      </Box>
    </>
  );
}

export { RichTextEditor };
